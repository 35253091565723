import { IAction } from '../../types/common.type';
import {
  BONUS_CLAIM_MODAL_STATUS_INFO,
  CHANGE_ACCESS_TOKEN,
  CHANGE_EMAIL,
  CHANGE_GLOBAL_DEPOSIT_MODAL,
  CHANGE_IS_CURRENT_COUNTRY_RESTRICTED,
  CHANGE_LANGUAGE,
  CHANGE_MAIN_HEADER_VISIBILITY,
  CHANGE_PHONE,
  CHANGE_RANKS_INFO,
  CHANGE_SIDEBAR_OPEN,
  CHANGE_USER_AUTHORIZATION,
  CHANGE_USER_INFO,
  CHANGE_USER_PROFILE_INFORMATION,
  CHANGE_WHEEL_SPINS_INFO,
  CLEAR_USER_INFO,
  GLOBAL_BONUS_WHEEL_MODAL_CLOSE,
  GLOBAL_BONUS_WHEEL_MODAL_OPEN,
  GLOBAL_USER_LOGIN_MODAL_OPEN,
  GLOBAL_USER_REGISTRATION_AND_LOGIN_MODAL_CLOSE,
  GLOBAL_USER_REGISTRATION_MODAL_OPEN,
  UPDATE_BALANCE,
  CHANGE_USER_INFOVERIFICATION_STATUS,
} from '../constants/userConstants';
import { setLocalStorageValue } from '../../util/appUtil';

const initialState = {
  isUserAuthorized: null,
  access_token: {
    expire_at: '',
    token: '',
  },
  playerInfo: {
    player: {},
  },
  language: 'en',
  isCountryRestricted: false,
  ranksInfo: [],
  userProfileInformation: {},
  globalUserLoginModalOpen: false,
  globalUserRegistrationModalOpen: false,
  globalBonusWheelModalOpen: false,
  bonusClaimModalStatusInfo: {
    success: false,
    open: false,
    claimedAmount: 0.0,
  },
  hideMainHeader: false,
  globalDepositModal: false,
  isSidebarOpen: false,
};

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case CHANGE_USER_AUTHORIZATION:
      return {
        ...state,
        isUserAuthorized: action.payload,
      };
    case CHANGE_USER_INFO:
      return {
        ...state,
        playerInfo: action.payload,
      };
    case CHANGE_ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case CHANGE_LANGUAGE:
      setLocalStorageValue('language', action.payload);
      return {
        ...state,
        language: action.payload,
      };
    case CHANGE_IS_CURRENT_COUNTRY_RESTRICTED:
      setLocalStorageValue('isCountryRestricted', action.payload);
      return {
        ...state,
        isCountryRestricted: action.payload,
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        playerInfo: {},
      };
    case CHANGE_RANKS_INFO:
      return {
        ...state,
        ranksInfo: action.payload,
      };
    case CHANGE_USER_PROFILE_INFORMATION:
      return {
        ...state,
        userProfileInformation: action.payload,
      };
    case CHANGE_EMAIL: {
      const nextState = structuredClone(state);
      return {
        ...nextState,
        playerInfo: {
          ...nextState.playerInfo,
          player: {
            ...nextState.playerInfo.player,
            email: action.payload,
          },
        },
      };
    }
    case CHANGE_PHONE: {
      const nextState = structuredClone(state);
      return {
        ...nextState,
        playerInfo: {
          ...nextState.playerInfo,
          player: {
            ...nextState.playerInfo.player,
            phone: action.payload,
          },
        },
      };
    }
    case GLOBAL_USER_LOGIN_MODAL_OPEN: {
      return {
        ...state,
        globalUserLoginModalOpen: true,
      };
    }
    case GLOBAL_USER_REGISTRATION_MODAL_OPEN: {
      return {
        ...state,
        globalUserRegistrationModalOpen: true,
      };
    }
    case GLOBAL_USER_REGISTRATION_AND_LOGIN_MODAL_CLOSE: {
      return {
        ...state,
        globalUserLoginModalOpen: false,
        globalUserRegistrationModalOpen: false,
      };
    }
    case GLOBAL_BONUS_WHEEL_MODAL_CLOSE: {
      return {
        ...state,
        globalBonusWheelModalOpen: false,
      };
    }
    case GLOBAL_BONUS_WHEEL_MODAL_OPEN: {
      return {
        ...state,
        globalBonusWheelModalOpen: true,
      };
    }
    case BONUS_CLAIM_MODAL_STATUS_INFO: {
      return {
        ...state,
        bonusClaimModalStatusInfo: action.payload,
      };
    }
    case CHANGE_WHEEL_SPINS_INFO: {
      return {
        ...state,
        playerInfo: {
          ...state.playerInfo,
          player: {
            ...state.playerInfo.player,
            wheel_spins: action.payload.wheel_spins,
            can_spin_after: action.payload.can_spin_after,
          },
        },
      };
    }
    case UPDATE_BALANCE: {
      const nextState = structuredClone(state);
      return {
        ...nextState,
        playerInfo: {
          ...nextState.playerInfo,
          player: {
            ...nextState.playerInfo.player,
            balance: action.payload,
          },
        },
      };
    }
    case CHANGE_MAIN_HEADER_VISIBILITY: {
      return {
        ...state,
        hideMainHeader: action.payload,
      };
    }
    case CHANGE_GLOBAL_DEPOSIT_MODAL: {
      return {
        ...state,
        globalDepositModal: action.payload,
      };
    }
    case CHANGE_SIDEBAR_OPEN: {
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    }
    case CHANGE_USER_INFOVERIFICATION_STATUS: {
      const { key, status } = action.payload;

      return {
        ...state,
        playerInfo: {
          ...state.playerInfo,
          player: { ...state.playerInfo.player, [key]: status },
        },
      };
    }
    default:
      return state;
  }
}
