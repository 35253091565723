import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IGameSchema } from '../../types/Game.type';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTranslation } from 'react-i18next';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { useDispatch } from 'react-redux';
import { changeGlobalUserLoginModalOpen } from '../../redux/actions/userActions';
import { SLOTSOFT } from '../Provider/constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '8px',
    borderRadius: '8px',
    background: '#030712',
    width: '100%',
    height: '248px',
  },
  content: {
    padding: '16px',
  },
  gameInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {},
  titleWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginLeft: '18px',
  },
  gameTitle: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#FFF',
  },
  provider: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#6B7280',
    marginTop: '8px',
  },
  buttonText: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 700,
  },
  favouriteWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
    color: '#6B7280',
    cursor: 'pointer',
    borderTop: '1px solid #FFFFFF0D',
    marginTop: '16px',
    paddingTop: '5px',
  },
  favouriteIcon: {
    color: '#6B7280',
    width: '20px',
    height: '20px',
  },
}));

interface PlayGamePageForMobileProps {
  gameInfo: IGameSchema | null;
  isFavourite: boolean;
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
  gameUrl: string | undefined;
  setGameType: (value: string | null) => void;
  isUserAuthenticated: boolean;
  setIsLoginOpen: (isOpen: boolean) => void;
  openGamesByProviders: () => void;
}

const PlayGamePageForMobile = ({
  gameInfo,
  isFavourite,
  addToFavoriteGameList,
  removeFromFavoriteGameList,
  gameUrl,
  setGameType,
  isUserAuthenticated,
  setIsLoginOpen,
  openGamesByProviders,
}: PlayGamePageForMobileProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openNewWindow = () => {
    window.open(gameUrl, '_blank');
  };

  const backgroundImageUrl = gameInfo?.image || '';
  const background = {
    backgroundImage: backgroundImageUrl
      ? `url(${getBaseUrlStatic() + '/' + (backgroundImageUrl[0] === '/' ? backgroundImageUrl.substring(1) : backgroundImageUrl)})`.replace(
          ' ',
          '%20'
        )
      : `${gameInfo?.agregator_image}`,
    backgroundSize: '100% 100%', // Adjusted property
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Box className={classes.gameInfo}>
          <Box
            style={{
              width: '96px',
              height: '128px',
              color: '#fff',
              ...background,
            }}
          ></Box>
          <Box className={classes.titleWrapper}>
            <span className={classes.gameTitle}>{gameInfo?.game_title}</span>
            <span
              className={classes.provider}
              onClick={() => openGamesByProviders && openGamesByProviders()}
            >
              {gameInfo?.provider}
            </span>
          </Box>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          flexDirection={'row'}
          gap={'8px'}
          marginTop={'16px'}
          justifyContent={'space-between'}
        >
          <CustomPrimaryButton
            style={{
              textTransform: 'uppercase',
              borderRadius: '6px',
              height: '32px',
              width: '100%',
              padding: '0px',
            }}
            fullWidth={'100%'}
            onClick={() => {
              if (isUserAuthenticated) {
                setGameType('real');
                openNewWindow();
              } else {
                setIsLoginOpen(true);
              }
            }}
          >
            <span className={classes.buttonText}>
              {isUserAuthenticated ? t('realPlay') : t('signUp')}
            </span>
          </CustomPrimaryButton>
          {gameInfo &&
            gameInfo.provider !== 'Inhouse' &&
            gameInfo.parent_provider !== SLOTSOFT && (
              <CustomPrimaryButton
                style={{
                  textTransform: 'uppercase',
                  background: '#111827',
                  borderRadius: '6px',
                  height: '32px',
                  boxShadow: 'none',
                  border: 'none',
                  width: '100%  ',
                }}
                fullWidth={'100%'}
                onClick={() => {
                  setGameType('demo');
                  openNewWindow();
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  <PlayArrowIcon sx={{ fontSize: '16px' }} />
                  <span className={classes.buttonText}>{t('demo')}</span>
                </div>
              </CustomPrimaryButton>
            )}
        </Box>
        <Box
          className={classes.favouriteWrapper}
          onClick={() => {
            !isUserAuthenticated
              ? dispatch(changeGlobalUserLoginModalOpen(true))
              : isFavourite
                ? gameInfo && removeFromFavoriteGameList(String(gameInfo.id))
                : gameInfo && addToFavoriteGameList(String(gameInfo.id));
          }}
        >
          <FavoriteIcon
            className={classes.favouriteIcon}
            style={{ color: isFavourite ? '#B8291D' : '#6B7280' }}
          />
          <span>
            {isFavourite ? t('removeFromFavourites') : t('addToFavourites')}
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default PlayGamePageForMobile;
