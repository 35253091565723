import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface LobbyCardProps {
  img: string;
  desc: string;
  label: string;
  title: string;
  altText: string;
  redirectLink: string;
  linearGradient: string;
  labelColor: string;
  labelBgColor: string;
}

const LobbyCard = ({
  img,
  desc,
  label,
  title,
  altText,
  redirectLink,
  linearGradient,
  labelColor,
  labelBgColor,
}: LobbyCardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const redirectBtn = {
    height: '40px',
    minWidth: '0px',
    width: '40px',
    background: '#1F2937',
  };

  const handleClick = () => {
    navigate(redirectLink);
  };

  return (
    <Box className={classes.root}>
      <Box
        className={classes.item}
        style={{
          background: linearGradient,
        }}
        onClick={handleClick}
      >
        <img src={img} alt={altText} width={isMobile ? '32px' : '52px'} />
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          boxSizing="border-box"
          gap={'8px'}
        >
          <Box
            display={'flex'}
            gap={'8px'}
            justifyContent={'left'}
            alignItems={'center'}
          >
            <span className={classes.itemTitle}>{title}</span>
            <Box
              className={classes.labelContainer}
              style={{ backgroundColor: labelBgColor }}
            >
              <span
                className={classes.labelStyle}
                style={{ color: labelColor }}
              >
                {label}
              </span>
            </Box>
          </Box>
          {!isMobile && <span className={classes.descStyle}>{desc}</span>}
        </Box>
        <CustomMinimalButton style={redirectBtn} onClick={() => {}}>
          <span className={classes.redirectBtnSpan}>
            <KeyboardArrowRightIcon />
          </span>
        </CustomMinimalButton>
      </Box>
    </Box>
  );
};

export default LobbyCard;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    gap: isMobile ? '8px' : '24px',
    flexDirection: isMobile ? 'column' : 'row',
    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column',
    },
  },
  item: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    padding: isMobile ? '16px' : '24px',
    gap: isMobile ? '16px 14px' : '24px',
    cursor: 'pointer',
  },
  itemTitle: {
    color: '#fff',
    fontSize: isMobile ? '19px' : '20px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  descStyle: {
    color: '#FFFFFF80',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  redirectBtnSpan: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  labelStyle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase',
    maxHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelContainer: {
    borderRadius: '8px',
    padding: '8px 8px',
    maxHeight: '28px',
  },
}));
