import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import { isMobile } from 'react-device-detect';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import LobbyIcon from '../../api/ui/svgIcons/LobbyIcon';
import { AppRoutePath } from '../../util/appRoutePath';
import SlotIcon from '../../api/ui/svgIcons/SlotIcon';
import LiveCasinoIcon from '../../api/ui/svgIcons/LiveCasinoIcon';
import TableGameIcon from '../../api/ui/svgIcons/TableGameIcon';
import GameShowIcon from '../../api/ui/svgIcons/GameShowIcon';
import AirbetOriginalMenuIcon from '../../api/ui/svgIcons/AirbetOriginalMenuIcon';
import CasinoMobileSwitcherIcon from './CasinoMobileSwitcherItem';
import { useLocation } from 'react-router-dom';
import HightRtp from '../../api/ui/svgIcons/HightRtp';

interface ICasinoMobileSwitcher {
  selectedPage: string;
}

export interface casinoMobileSwiperData {
  text: string;
  icon: any;
  link: string;
  isActive: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    gap: '6px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    minWidth: '0px',
    marginTop: isMobile ? '12px' : '48px',
    padding: '0px 16px',
    position: 'relative',
    justifyContent: 'center',
  },
  headerContainer: {
    padding: isMobile ? '0px' : '0px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    gap: isMobile ? '8px' : '16px',
    display: 'flex',
    position: 'absolute',
    right: '10px',
    zIndex: '2',
  },
  gameContainerSwiper: {},
}));

const CasinoMobileSwitcher = ({ selectedPage }: ICasinoMobileSwitcher) => {
  const location = useLocation();
  const swiperRef = useRef<any>(null);
  const classes = useStyles();

  const [visibleSlidesCount, setVisibleSlidesCount] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [partiallyVisibleSlides, setPartiallyVisibleSlides] = useState(
    new Set<number>()
  );

  const casinoMobileData: casinoMobileSwiperData[] = [
    {
      icon: LobbyIcon,
      link: AppRoutePath.CASINO(),
      text: 'airbetMenu.lobby',
      isActive: selectedPage === 'lobby',
    },
    {
      icon: SlotIcon,
      link: AppRoutePath.GAMELIST(),
      text: 'airbetMenu.slots',
      isActive: selectedPage === 'slotGames',
    },
    {
      icon: HightRtp,
      link: AppRoutePath.HIGHRTP(),
      text: 'airbetMenu.highRTP',
      isActive: selectedPage === 'airbetMenu.highRTPSCREENDESC',
    },
    {
      icon: LiveCasinoIcon,
      link: AppRoutePath.LIVECASINO(),
      text: 'airbetMenu.liveCasino',
      isActive: selectedPage === 'airbetMenu.liveCasino',
    },
    {
      icon: TableGameIcon,
      link: AppRoutePath.TABLE(),
      text: 'airbetMenu.tableGames',
      isActive: selectedPage === 'airbetMenu.tableGames',
    },
    {
      icon: GameShowIcon,
      link: AppRoutePath.GAME_SHOWS(),
      text: 'airbetMenu.gameShows',
      isActive: selectedPage === 'airbetMenu.gameShows',
    },
    {
      icon: AirbetOriginalMenuIcon,
      link: AppRoutePath.ORIGINAL(),
      text: 'originals',
      isActive: selectedPage === 'airbetMenu.airbetOriginals',
    },
  ];

  useEffect(() => {
    const findIndex: number = casinoMobileData.findIndex(
      (item: casinoMobileSwiperData) => item.link === location.pathname
    );
    scrollToIndex(findIndex);
  }, []);

  useEffect(() => {
    const updateButtonStates = () => {
      if (swiperRef.current) {
        setIsAtEnd(swiperRef.current.isEnd);
      }
    };

    updateButtonStates();

    const swiperInstance = swiperRef.current;
    swiperInstance.on('slideChange', updateButtonStates);

    return () => {
      swiperInstance.off('slideChange', updateButtonStates);
    };
  }, []);

  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('slideChangeTransitionEnd', updateSlideVisibility);
      swiperInstance.on('init', updateSlideVisibility);
    }
    updateSlideVisibility();
    return () => {
      if (swiperInstance) {
        swiperInstance.off('slideChangeTransitionEnd', updateSlideVisibility);
        swiperInstance.off('init', updateSlideVisibility);
      }
    };
  }, []);

  useEffect(() => {
    const updateSlideVisibility = () => {
      const swiper = swiperRef.current;
      if (!swiper || !swiper.slides) return;

      const newPartiallyVisibleSlides = new Set<number>();
      swiper.slides.forEach((slide: HTMLElement, index: number) => {
        const slideRect = slide.getBoundingClientRect();
        const swiperRect = swiper.el.getBoundingClientRect();

        const isPartiallyVisible =
          slideRect.left < swiperRect.left ||
          slideRect.right > swiperRect.right;

        if (isPartiallyVisible) {
          newPartiallyVisibleSlides.add(index);
        }
      });

      setPartiallyVisibleSlides(newPartiallyVisibleSlides);
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('init', updateSlideVisibility);
      swiperInstance.on('slideChangeTransitionEnd', updateSlideVisibility);

      updateSlideVisibility();
    }

    window.addEventListener('resize', updateSlideVisibility);

    return () => {
      if (swiperInstance) {
        swiperInstance.off('init', updateSlideVisibility);
        swiperInstance.off('slideChangeTransitionEnd', updateSlideVisibility);
        window.removeEventListener('resize', updateSlideVisibility);
      }
    };
  }, []);

  useEffect(() => {
    const handleResizeOrInit = () => {
      const swiper = swiperRef.current;
      if (swiper) {
        swiper.update(); // Make sure Swiper updates its internal state
        updateVisibleSlidesCount(swiper);
      }
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('init', handleResizeOrInit);
    }
    window.addEventListener('resize', handleResizeOrInit);

    // Initial calculation
    if (swiperInstance) {
      handleResizeOrInit();
    }

    return () => {
      window.removeEventListener('resize', handleResizeOrInit);
      if (swiperInstance) {
        swiperInstance.off('init', handleResizeOrInit);
      }
    };
  }, []);

  const updateVisibleSlidesCount = (swiper: any) => {
    if (!swiper || !swiper.slides.length || !swiper.slides[0]) return;

    // Assuming swiper.slides[0] exists and has uniform width across all slides
    const slideWidth =
      swiper.slides[0].offsetWidth + swiper.params.spaceBetween;
    const count = Math.floor(swiper.width / slideWidth);
    setVisibleSlidesCount(count);
  };

  const updateSlideVisibility = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    const newPartiallyVisibleSlides = new Set<number>();
    swiper.slides.forEach((slide: HTMLElement, index: number) => {
      const slideLeftEdge = slide.offsetLeft;
      const slideRightEdge = slideLeftEdge + slide.offsetWidth;
      const swiperVisibleLeftEdge = swiper.translate;
      const swiperVisibleRightEdge = swiperVisibleLeftEdge + swiper.width;

      if (
        slideLeftEdge < swiperVisibleLeftEdge ||
        slideRightEdge > swiperVisibleRightEdge
      ) {
        newPartiallyVisibleSlides.add(index);
      }
    });

    setPartiallyVisibleSlides(newPartiallyVisibleSlides);
  };

  const goRight = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const slidesCount = swiper.slides.length;
    const newIndex = Math.min(
      currentIndex + visibleSlidesCount,
      slidesCount - visibleSlidesCount
    );
    swiper.slideTo(newIndex);
  };

  const scrollToIndex = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index, 0);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.buttonsContainer}>
        <CustomMinimalButton
          style={{
            padding: isMobile ? '6px' : '10px',
            height: isMobile ? '32px' : '40px',
            minWidth: '0px',
            width: isMobile ? '32px' : '40px',
          }}
          onClick={() => goRight()}
          disabled={isAtEnd}
        >
          <span
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <KeyboardArrowRightIcon />
          </span>
        </CustomMinimalButton>
      </Box>

      <Box className={classes.gameContainerSwiper}>
        <Swiper
          spaceBetween={isMobile ? 8 : 12}
          slidesPerView={'auto'}
          onSlideChange={() => {}}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          style={{
            paddingTop: '10px',
          }}
          speed={500}
        >
          {casinoMobileData.map(
            (item: casinoMobileSwiperData, index: number) => {
              const isPartiallyVisible = partiallyVisibleSlides.has(index);
              return (
                <SwiperSlide
                  style={{
                    color: '#fff',
                    minWidth: '0px',
                    width: 'fit-content',
                    transition: 'opacity 0.2s ease-in-out',
                    opacity: isPartiallyVisible ? 0.1 : 1,
                  }}
                  key={index}
                >
                  <Box key={index}>
                    <CasinoMobileSwitcherIcon item={item} />
                  </Box>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </Box>
    </Box>
  );
};

export default CasinoMobileSwitcher;
