import CustomModal from '../../common/Modal/CustomModal';
import { Box, SelectChangeEvent } from '@mui/material';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import CustomSettingsSelect from '../CustomSettingsSelect';
import { ICountry, IUserInfo } from '../../../types/common.type';
import { personalInfo } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import ModalHeader from '../ModalHeader';
import { isMobile } from 'react-device-detect';
import PersonalInput from './PersonalInput';
import cn from 'classnames';
import { userInfoSchema } from './schema';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 16px',
  },
  PersonalModal: {
    color: '#fff',
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    minWidth: isMobile ? '0px' : '528px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  sectionContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',
    padding: '0 16px',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  inputContainer: {
    marginTop: '4px',
    display: 'flex',
    width: '100%',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',
    height: '48px',
    borderRadius: '8px',
    flex: 1,
    width: '100%',
    transition: 'border-color 0.3s ease-in-out',
  },
  customButtonBox: {
    display: 'flex',
    margin: '32px 16px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
  countriesList: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& div': {
      display: 'flex',
      height: '16px',
      width: '24px',
      overflow: 'hidden',
      '& img': {
        height: '100%',
        width: '100%',
      },
    },
  },
}));

interface IPersonalModal {
  isOpen: boolean;
  onClose: () => void;
  countries: ICountry[];
  playerInfo: IUserInfo;
}

interface ErrorInterface {
  first_name: string;
  last_name: string;
  country: string;
  gender: string;
  day: string;
  month: string;
  year: string;
  address: string;
  city: string;
  postal_code: string;
}

const PersonalModal = ({
  isOpen,
  onClose,
  countries,
  playerInfo,
}: IPersonalModal) => {
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState<string>(playerInfo.player.gender || '');
  const [currentCountry, setCurrentCountry] = useState<string>('');
  const [firstName, setFirstName] = useState<string>(
    playerInfo.player.first_name || ''
  );
  const [lastName, setLastName] = useState<string>(
    playerInfo.player.last_name || ''
  );

  const [day, setDay] = useState<string>(
    playerInfo.player.birthday?.toString().split('-')[2] || ''
  );
  const [month, setMonth] = useState<string>(
    playerInfo.player.birthday?.toString().split('-')[1] || ''
  );
  const [year, setYear] = useState<string>(
    playerInfo.player.birthday?.toString().split('-')[0] || ''
  );

  const [address, setAddressName] = useState<string>(
    playerInfo.player.address || ''
  );
  const [city, setCity] = useState<string>(playerInfo.player.city || '');
  const [postalCode, setPostalCode] = useState<string>(
    playerInfo.player.postal_code || ''
  );

  const [errors, setError] = useState<ErrorInterface>({
    first_name: '',
    last_name: '',
    day: '',
    month: '',
    year: '',
    country: '',
    gender: '',
    address: '',
    city: '',
    postal_code: '',
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const genders = ['Male', 'Female'];

  const countryIso = useMemo(() => {
    const res = countries.find((country) => country.name === currentCountry);
    if (res) {
      return res.iso;
    }
    return '';
  }, [currentCountry]);

  const changeYearHandler = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    if (/^[1-9]\d{4}$/.test(numericValue) || numericValue === '0') return;
    setYear(numericValue);
  };

  const changeDayHandler = (value: string) => {
    if (value === '00') {
      setDay('0');
      return;
    }
    const numericValue = value.replace(/\D/g, '');
    if (/^[0-9]\d{2}$/.test(numericValue)) return;
    setDay(numericValue);
  };

  const changeMonthHandler = (value: string) => {
    if (value === '00') {
      setMonth('0');
      return;
    }
    const numericValue = value.replace(/\D/g, '');
    if (/^[0-9]\d{2}$/.test(numericValue)) return;
    setMonth(numericValue);
  };

  const updatePersonalInfo = () => {
    userInfoSchema
      .validate(
        {
          first_name: firstName,
          last_name: lastName,
          country: countryIso,
          gender: gender,
          day,
          month,
          year,
          address: address,
          city: city,
          postal_code: postalCode,
        },
        { abortEarly: false }
      )
      .then(() => {
        setLoading(true);

        personalInfo({
          first_name: firstName,
          last_name: lastName,
          country: countryIso,
          gender: gender,
          birthday: `${year}-${month}-${day}`,
          address: address,
          city: city,
          postal_code: postalCode,
        })
          .then((res) => {
            if (res.data.success) {
              enqueueSnackbar(t(res.data.data.message), {
                variant: 'success',
              });
              onClose();
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      })
      .catch((err: yup.ValidationError) => {
        const errObj: ErrorInterface = {
          first_name: '',
          last_name: '',
          country: '',
          gender: '',
          day: '',
          month: '',
          year: '',
          address: '',
          city: '',
          postal_code: '',
        };

        err.inner.forEach((error) => {
          if (error.path) {
            errObj[error.path as keyof ErrorInterface] = error.message;
          }
        });

        setError(errObj);
        enqueueSnackbar(t('fillAllFields'), {
          variant: 'error',
        });
      });
  };

  const gendersData = () => {
    return genders.map((gender) => {
      return {
        value: gender,
        renderer: () => <Box color={'#9CA3AF'}>{gender}</Box>,
      };
    });
  };

  useEffect(() => {
    const country = countries.find(
      (country) => country.iso === playerInfo.player.country
    );
    setCurrentCountry(country?.name || '');
  }, []);

  const countriesData = () => {
    return countries.map((country) => {
      return {
        value: country.name,
        renderer: () => (
          <Box width={'100%'} color={'#9CA3AF'}>
            <div className={classes.countriesList}>
              <span>{country.name}</span>
              <div>
                <img
                  src={`/imgs/Flags/${country.iso.toLowerCase()}.png`}
                  alt="flag"
                />
              </div>
            </div>
          </Box>
        ),
      };
    });
  };

  const onChangeGender = (e: SelectChangeEvent) => {
    setGender(e.target.value as string);
  };

  const onChangeCountry = (e: SelectChangeEvent) => {
    setCurrentCountry(e.target.value as string);
  };

  const children = (
    <Box
      className={cn(classes.PersonalModal, '!h-full !min-h-full')}
      id="personalModal"
    >
      <ModalHeader onClose={onClose} heading={t('personalInformation')} />
      <Box
        className={`${classes.sectionContainer} ${isMobile ? classes.flexColumn : ''}`}
      >
        <PersonalInput
          onChange={(e) => setFirstName(e.target.value)}
          label={t('firstName')}
          placeHolder={t('enterFirstName')}
          value={firstName}
          error={errors['first_name']}
        />
        <PersonalInput
          onChange={(e) => setLastName(e.target.value)}
          label={t('lastName')}
          placeHolder={t('enterLastName')}
          value={lastName}
          error={errors['last_name']}
        />
      </Box>
      <Box className={classes.sectionContainer}>
        <Box flex={1}>
          <div className={classes.title}>{t('dateOfBirth')}</div>
          <div className={classes.inputContainer} style={{ gap: '16px' }}>
            <input
              className={classes.input}
              placeholder={'DD'}
              value={day}
              style={{
                border: errors['day']
                  ? '1px solid #d32f2f'
                  : '1px solid transparent',
              }}
              onChange={(e) => changeDayHandler(e.target.value)}
            />
            <input
              className={`${classes.input}`}
              placeholder={'MM'}
              value={month}
              style={{
                border: errors['month']
                  ? '1px solid #d32f2f'
                  : '1px solid transparent',
              }}
              onChange={(e) => changeMonthHandler(e.target.value)}
            />
            <input
              className={`${classes.input}`}
              placeholder={'YYYY'}
              value={year}
              style={{
                border: errors['year']
                  ? '1px solid #d32f2f'
                  : '1px solid transparent',
              }}
              onChange={(e) => changeYearHandler(e.target.value)}
            />
          </div>
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Box width={'100%'}>
          <div className={classes.title}>{t('gender')}</div>
          <CustomSettingsSelect
            error={errors['gender']}
            onChange={onChangeGender}
            value={
              gender
                ? gender
                : playerInfo.player.gender
                  ? playerInfo.player.gender
                  : ''
            }
            data={gendersData()}
            separated={false}
          />
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Box width={'100%'}>
          <div className={classes.title}>{t('countryOfResidence')}</div>
          <CustomSettingsSelect
            error={errors['country']}
            onChange={onChangeCountry}
            value={currentCountry}
            data={countriesData()}
            separated={false}
          />
        </Box>
      </Box>
      <Box marginTop={'16px'} padding={'0 16px'}>
        <PersonalInput
          error={errors['address']}
          onChange={(e) => setAddressName(e.target.value)}
          label={t('streetAddress')}
          placeHolder={t('enterStreetAddress')}
          value={address}
        />
      </Box>
      <Box
        className={`${classes.sectionContainer} ${isMobile ? classes.flexColumn : ''}`}
      >
        <PersonalInput
          error={errors['city']}
          onChange={(e) => setCity(e.target.value)}
          label={t('city')}
          placeHolder={t('enterCity')}
          value={city}
        />
        <PersonalInput
          error={errors['postal_code']}
          onChange={(e) => setPostalCode(e.target.value)}
          label={t('postCode')}
          placeHolder={t('enterPostcode')}
          value={postalCode}
        />
      </Box>
      <Box
        className={cn(classes.customButtonBox, isMobile ? 'mb-[120px]' : '')}
      >
        <CustomPrimaryButton
          onClick={updatePersonalInfo}
          loading={loading}
          style={{
            textTransform: 'upperCase',
          }}
        >
          <div>{t('verify')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default PersonalModal;
