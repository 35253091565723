import React, { FC, useEffect, useState } from 'react';
import PlayGamePage from '../../components/PlayGamePage/PlayGamePage';
import {
  getDemoGameUrl,
  getGameInfoBySlug,
  getRealGameUrl,
  getRecommendedGames,
} from '../../api/services/Game.api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IGameSchema } from '../../types/Game.type';
import { bindActionCreators } from 'redux';
import {
  addGameToFavourite,
  removeGameFromFavourite,
} from '../../redux/actions/gameActions';
import CustomModal from '../../components/common/Modal/CustomModal';
import { makeStyles } from '@mui/styles';
import { IUserInfo } from '../../types/common.type';

interface PlayGameContainerProps {
  isUserAuthenticated: boolean;
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
  favourite: IGameSchema[];
  playerInfo: IUserInfo;
}
import CloseIcon from '@mui/icons-material/Close';
import AirbetAuthorizationIcon from '../../api/ui/svgIcons/AirbetAuthorizationIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#111827',
    borderRadius: '12px',
    width: '360px',
    height: '480px',
    position: 'relative',
    overflow: 'hidden',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: '240px',
  },
  title: {
    color: '#fff',
    lineHeight: '24px',
    fontSize: '24px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textAlign: 'center',
  },
  description: {
    color: '#FFFFFF80',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    letterSpacing: '0.5px',
    marginTop: '16px',
  },
  icon: {
    position: 'absolute',
    bottom: '-140px',
  },
  close: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    backgroundColor: '#0D121D',
    color: '#fff',
    padding: '7px',
    borderRadius: '8px',
    cursor: 'pointer',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '9px',
      height: '9px',
    },
  },
}));

const PlayGameContainer: FC<PlayGameContainerProps> = ({
  isUserAuthenticated,
  addToFavoriteGameList,
  removeFromFavoriteGameList,
  favourite,
  playerInfo,
}) => {
  const { slug } = useParams();
  const [gameType, setGameType] = useState<null | string>(null);
  const [gameInfo, setGameInfo] = useState<null | IGameSchema>(null);
  const [gameUrl, setGameUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [recommendedGames, setRecommendedGames] = useState<IGameSchema[]>([]);
  const [openRestrictedModal, setOpenRestrictedModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    if (slug) {
      getInfoBySlug(slug);
    }

    onLoadRecommendedGame();
  }, [slug]);

  useEffect(() => {
    if (isUserAuthenticated) {
      setGameType('real');
    } else {
      setGameType(null);
      setLoading(false);
    }
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (gameType !== null && gameInfo !== null) {
      setLoading(true);
      if (gameType === 'real') {
        loadRealGameUrl(gameInfo['game_id']);
      } else {
        loadDemoGameUrl(gameInfo['game_id']);
      }
    }

    if (gameInfo !== null && gameInfo.is_restricted) {
      setOpenRestrictedModal(true);
    }
  }, [gameType, gameInfo]);

  const getInfoBySlug = (gameName: string) => {
    setLoading(true);
    getGameInfoBySlug(gameName)
      .then((resp) => {
        if (resp.data.data) {
          setGameInfo(resp.data.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadDemoGameUrl = (gameId: string) => {
    getDemoGameUrl(gameId)
      .then((resp) => {
        if (!resp.data.data.is_restricted) {
          setGameUrl(resp.data.data.url);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const loadRealGameUrl = (gameId: string) => {
    getRealGameUrl(gameId)
      .then((resp) => {
        if (!resp.data.data.is_restricted) {
          setGameUrl(resp.data.data.url);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onLoadRecommendedGame = () => {
    getRecommendedGames().then((resp: any) => {
      const games = resp.data.data;
      if (games) {
        setRecommendedGames(games);
      }
    });
  };

  const classes = useStyles({});
  const { t } = useTranslation();

  return (
    <>
      <PlayGamePage
        gameUrl={gameUrl}
        loading={loading}
        gameInfo={gameInfo}
        playerInfo={playerInfo}
        isUserAuthenticated={isUserAuthenticated}
        setGameType={setGameType}
        gameType={gameType}
        addToFavoriteGameList={addToFavoriteGameList}
        removeFromFavoriteGameList={removeFromFavoriteGameList}
        favourite={favourite}
        recommendedGames={recommendedGames}
        setOpenRestrictedModal={setOpenRestrictedModal}
      />
      <CustomModal
        open={openRestrictedModal}
        onClose={() => setOpenRestrictedModal(false)}
      >
        <div className={classes.root}>
          <div
            className={classes.close}
            onClick={() => setOpenRestrictedModal(false)}
          >
            <CloseIcon />
          </div>
          <div className={classes.icon}>
            <AirbetAuthorizationIcon />
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.title}>{t('gameRestriction.title')}</div>
            <div className={classes.description}>
              {t('gameRestriction.description')}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
  playerInfo: state.user.playerInfo,
  favourite: state.game.favourite,
});

const dispatchToProps = (dispatch: any) => ({
  addToFavoriteGameList: bindActionCreators(addGameToFavourite, dispatch),
  removeFromFavoriteGameList: bindActionCreators(
    removeGameFromFavourite,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(PlayGameContainer);
