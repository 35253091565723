import { Navigate, Route, Routes } from 'react-router-dom';
import LobbyContainer from './Lobby/LobbyContainer';
import { AppRoutePath } from '../util/appRoutePath';
import Account from '../components/Account/Account';
import GameShowContainer from './Games/GameShowContainer';
import TableGameContainer from './Games/TableGameContainer';
import InstantGameContainer from './Games/InstantGameContainer';
import SlotContainer from './Games/SlotContainer';
import HighRTPContainer from './Games/HighRTPContainer';
import LiveCasinoContainer from './Games/LiveCasinoContainer';
import GoogleAuthContainer from './GoogleAuth/GoogleAuthContainer';
import AirbetOriginalContainer from './Games/AirbetOriginalContainer';
import RecentContainer from './Games/RecentContainer';
import FavouriteGameContainer from './Games/FavouriteGameContainer';
import ProviderContainer from './Provider/ProviderContainer';
import BlogContainer from './Blog/BlogContainer';
import BlogDetailsContainer from './Blog/BlogDetailsContainer';
import BetsTableContainer from './BetsTable/BetsTableContainer';
import Settings from '../components/Settings/Settings';
import EmailVerificationPage from '../components/Settings/AccountInfo/EmailVerificationPage';
import PlayGameContainer from './Games/PlayGameContainer';
import AccountMobilePage from '../components/Settings/Mobile/AccountMobilePage';
import BonusesContainer from './Bonuses/BonusesContainer';
import WalletMobilePage from '../components/Account/Mobile/WalletMobilePage';
import PromotionContainer from '../components/Promotion/PromotionContainer';
import PromotionItemContainer from '../components/Promotion/items/PromotionItemContainer';
import ResetPasswordContainer from './ResetPassword.tsx/ResetPasswordContainer';
import RankSystemContainer from './RankSystem/RankSystemContainer';
import PoliciesContainer from './Policies/PoliciesContainer';
import BetBy from '../components/BetBy/BetBy';
import LeaderBoardsContainer from './LeaderBoards/LeaderBoardsContainer';
import Affiliate from './Affiliate/Affiliate';
import CasinoContainer from './Casino/CasinoContainer';
import Example from '../components/BetBy/example';
import LotteryGameContainer from './Games/LotteryGameContainer';
import RegistrationModalOpenerPage from '../components/RegistrationModalOpenerPage/RegistrationModalOpenerPage';
import MAffiliate from './Affiliate/MAffiliate';

interface IAppRoutes {
  isUserAuthorized: boolean;
}

const AppRoutes = ({ isUserAuthorized }: IAppRoutes) => {
  return (
    <Routes>
      <Route path={AppRoutePath.HOME()} element={<LobbyContainer />} />
      <Route path={AppRoutePath.CASINO()} element={<CasinoContainer />} />
      {isUserAuthorized && (
        <Route path={AppRoutePath.ACCOUNT()} element={<Account />} />
      )}
      <Route path={AppRoutePath.POLICIES()} element={<PoliciesContainer />} />
      {isUserAuthorized && (
        <Route path={AppRoutePath.SETTINGS()} element={<Settings />} />
      )}
      <Route
        path={AppRoutePath.PROVIDER_LIST_PAGE()}
        element={<ProviderContainer />}
      />
      <Route path={AppRoutePath.GAMELIST()} element={<SlotContainer />} />
      <Route path={AppRoutePath.HIGHRTP()} element={<HighRTPContainer />} />
      <Route
        path={AppRoutePath.LIVECASINO()}
        element={<LiveCasinoContainer />}
      />
      <Route path={AppRoutePath.INSTANT()} element={<InstantGameContainer />} />
      <Route path={AppRoutePath.LOTERRY()} element={<LotteryGameContainer />} />
      <Route path={AppRoutePath.TABLE()} element={<TableGameContainer />} />
      <Route path={AppRoutePath.GAME_SHOWS()} element={<GameShowContainer />} />
      <Route path={'bets'} element={<BetsTableContainer />} />
      <Route
        path={AppRoutePath.ORIGINAL()}
        element={<AirbetOriginalContainer />}
      />
      {isUserAuthorized && (
        <Route path={AppRoutePath.LASTPLAYED()} element={<RecentContainer />} />
      )}
      {isUserAuthorized && (
        <Route
          path={AppRoutePath.FAVORITE_LIST_PATE()}
          element={<FavouriteGameContainer />}
        />
      )}
      <Route
        path={AppRoutePath.SOCIAL_AUTH(':type', ':access_token')}
        element={<GoogleAuthContainer />}
      />
      <Route path={AppRoutePath.BLOG()} element={<BlogContainer />} />
      <Route
        path={AppRoutePath.BLOGBYSLUG()}
        element={<BlogDetailsContainer />}
      />
      <Route
        path={AppRoutePath.EMAIL_VERIFY()}
        element={<EmailVerificationPage />}
      />
      <Route path={AppRoutePath.GAMES()} element={<PlayGameContainer />} />
      <Route
        path="/example1"
        element={
          <div
            style={{ width: '300px', marginLeft: '100px', marginTop: '100px' }}
          ></div>
        }
      />
      <Route
        path={AppRoutePath.ACCOUNT_MOBILE_PAGE()}
        element={<AccountMobilePage />}
      />
      <Route path={AppRoutePath.BONUS()} element={<BonusesContainer />} />
      <Route
        path={AppRoutePath.WALLET_MOBILE_PAGE()}
        element={<WalletMobilePage />}
      />
      <Route path={AppRoutePath.PROMOTION()} element={<PromotionContainer />} />
      <Route
        path={AppRoutePath.RESETPASSWORD()}
        element={<ResetPasswordContainer />}
      />
      <Route
        path={AppRoutePath.RANKSYSTEM()}
        element={<RankSystemContainer />}
      />
      <Route
        path={AppRoutePath.PROMOTIONITEM()}
        element={<PromotionItemContainer />}
      />
      <Route path={AppRoutePath.AFFILIATE()} element={<Affiliate />} />
      <Route path={AppRoutePath.MAFFILIATE()} element={<MAffiliate />} />
      <Route
        path={AppRoutePath.LEADERBOARDS()}
        element={<LeaderBoardsContainer />}
      />
      {/* {process.env.REACT_APP_MODE === 'development' && (*/}
      <Route path={AppRoutePath.SPORT()} element={<BetBy />} />
      {/*)}*/}
      {process.env.REACT_APP_MODE === 'development' && (
        <Route path="banner" element={<Example />} />
      )}
      <Route
        path={AppRoutePath.REGISTRATION()}
        element={<RegistrationModalOpenerPage />}
      />
      <Route path="*" element={<Navigate to={AppRoutePath.HOME()} />} />
    </Routes>
  );
};

export default AppRoutes;
