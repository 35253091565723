import * as React from 'react';
import AirbetLogo from '../../api/ui/svgIcons/AirbetLogo';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { isMobile } from 'react-device-detect';
import cn from 'classnames'

interface ICountryRestrictionModal {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const CountryRestrictionModal = ({
  open,
  setOpen,
}: ICountryRestrictionModal) => {
  console.log(open, setOpen)

  const { t } = useTranslation();

  const handleButtonClick = () => {
    window.open('https://t.me/airbetofficial', '_blank')
  }

  return (
    <>
      {open && (
        <>
          {!isMobile && (
            <div className={cn("fixed z-[999999999999] bg-[#0D121D] top-0 left-0 right-0 bottom-0 flex flex-col items-center bg-[url('/public/imgs/VPN_Background.png')] bg-cover bg-center")}>
              <AirbetLogo className='mt-[48px] shrink-0' width={125} height={32} />
              <div className='flex flex-col items-center justify-center self-center h-[100%]'>
                <h1 className='font-titillium text-[58px] font-bold leading-[56px] text-[#fff] max-w-[750px] text-center'>
                  {t('vpn_header').toUpperCase()}
                </h1>
                <p className='text-[34px] mt-4 text-center'>
                  <span className='font-dela-white'>
                    {t('vpn_desc_1').toUpperCase()}
                  </span>
                  <span className='font-dela-blue'>
                    {t('vpn_desc_2').toUpperCase()}
                  </span>
                  <span className='font-dela-white'>
                    {t('vpn_desc_3').toUpperCase()}
                  </span>
                </p>
                <div className='mt-10'>
                  <CustomPrimaryButton
                    onClick={handleButtonClick}
                  >
                    {t('vpn_button').toUpperCase()}
                  </CustomPrimaryButton>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className={cn("fixed z-[999999999999] bg-[#0D121D] top-0 left-0 right-0 bottom-0 flex flex-col items-center ")}>
              <img src="/imgs/VPN_Background.png" className='w-[180%] h-[60%] absolute -top-30%' />
              <AirbetLogo className='mt-[48px] shrink-0' width={78} height={20} />
              <div className='flex flex-col items-center justify-start mt-[30%] self-center h-[100%] z-[9999999999999]'>
                <h1 className='font-titillium text-[24px] font-bold leading-[32px] text-[#fff] max-w-[340px] text-center'>
                  {t('vpn_header').toUpperCase()}
                </h1>
                <p className='text-[12px] mt-4 text-center'>
                  <span className='font-dela-white'>
                    {t('vpn_desc_1').toUpperCase()}
                  </span>
                  <span className='font-dela-blue'>
                    {t('vpn_desc_2').toUpperCase()}
                  </span>
                  <span className='font-dela-white'>
                    {t('vpn_desc_3').toUpperCase()}
                  </span>
                </p>
              </div>
              <div className='mb-10'>
                <CustomPrimaryButton
                  onClick={handleButtonClick}
                >
                  {t('vpn_button').toUpperCase()}
                </CustomPrimaryButton>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CountryRestrictionModal;
