import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import WalletIcon from '../../api/ui/svgIcons/WalletIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import { Box, Container } from '@mui/material';
import SettingIcon from '../../api/ui/svgIcons/SettingIcon';
import AccountInfoContainer from '../../containers/Settings/AccountInfoContainer';
import SecurityContainer from '../../containers/Settings/SecurityContainer';
import PreferencesContainer from '../../containers/Settings/PreferencesContainer';
import { VerificationIcon } from '../../api/ui/svgIcons/VerificationIcon';
import { PreferenceIcon } from '../../api/ui/svgIcons/PreferencesIcon';
import PersonalVerificationContainer from '../../containers/Settings/PersonalVerificationContainer';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    ...theme.general.pageHeader,
    padding: '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
  },
  body: {
    padding: !isMobile ? '40px 20px' : '',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
  },
  bodyContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '32px',
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    margin: '0 auto',
    maxWidth: '1200px',
    boxSizing: 'border-box',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '272px',
    padding: '8px',
    borderRadius: '12px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxSizing: 'border-box',
  },
  listContainerItem: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '10px',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'background 0.3s ease-in-out',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',

    '& span:hover': {
      color: '#fff !important',
    },
  },
  icon: {
    transition: 'fill 0.3s ease-in-out',
  },
}));

const Settings = () => {
  const { t } = useTranslation();
  const { settingsType } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/account');
  };
  const navigateToPage = (url: string) => {
    navigate(url);
  };

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.header}>
          <Container style={{ padding: '0' }}>
            <div className={classes.headerText}>{t('settings')}</div>
          </Container>
        </div>
      )}
      <div className={classes.body}>
        <div className={classes.bodyContent}>
          {!isMobile && (
            <div className={classes.listContainer}>
              <div
                className={classes.listContainerItem}
                onClick={() => navigateToPage(AppRoutePath.ACCOUNT_INFO())}
                style={{
                  background: settingsType === 'account-info' ? '#0D121D' : '',
                }}
              >
                <WalletIcon pathClassName={classes.icon} fill={'#9CA3AF'} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      transition: 'color 0.3s ease-in-out',
                      color:
                        settingsType === 'account-info' ? '#fff' : '#9CA3AF',
                    }}
                  >
                    {t('accountInfo')}
                  </span>
                </div>
              </div>
              <div
                className={classes.listContainerItem}
                onClick={() => navigateToPage(AppRoutePath.SECURITY())}
                style={{
                  background: settingsType === 'security' ? '#0D121D' : '',
                }}
              >
                <SettingIcon pathClassName={classes.icon} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      transition: 'color 0.3s ease-in-out',
                      color: settingsType === 'security' ? '#fff' : '#9CA3AF',
                    }}
                  >
                    {t('security')}
                  </span>
                </div>
              </div>
              <div
                className={classes.listContainerItem}
                onClick={() => navigateToPage(AppRoutePath.PREFERENCES())}
                style={{
                  background: settingsType === 'preferences' ? '#0D121D' : '',
                }}
              >
                <PreferenceIcon
                  pathClassName={classes.icon}
                  size={16}
                  fill={'#9CA3AF'}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      transition: 'color 0.3s ease-in-out',
                      color:
                        settingsType === 'preferences' ? '#fff' : '#9CA3AF',
                    }}
                  >
                    {t('preferences')}
                  </span>
                </div>
              </div>
              <div
                className={classes.listContainerItem}
                onClick={() => navigateToPage(AppRoutePath.VERIFICATION())}
                style={{
                  background: settingsType === 'verification' ? '#0D121D' : '',
                }}
              >
                <VerificationIcon
                  pathClassName={classes.icon}
                  size={16}
                  fill={'#9CA3AF'}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      transition: 'color 0.3s ease-in-out',
                      color:
                        settingsType === 'verification' ? '#fff' : '#9CA3AF',
                    }}
                  >
                    {t('personalVerification')}
                  </span>
                </div>
              </div>
            </div>
          )}

          <Box
            width={'100%'}
            height={isMobile ? '100%' : 'auto'}
            boxSizing={'border-box'}
            overflow={'scroll'}
            bgcolor={'#111827'}
          >
            {settingsType === 'account-info' && (
              <AccountInfoContainer onClose={onClose} />
            )}
            {settingsType === 'security' && (
              <SecurityContainer onClose={onClose} />
            )}
            {settingsType === 'preferences' && (
              <PreferencesContainer onClose={onClose} />
            )}
            {settingsType === 'verification' && (
              <PersonalVerificationContainer onClose={onClose} />
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Settings;
