import React, { ChangeEvent, FC } from 'react';
import { Box } from '@mui/material';
import CustomInput from '../common/Inputs/CustomInput';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { useEffect } from 'react';

interface ILogin {
  onChangeLoginInfo: (key: string, value: string) => void;
  disableLogin: boolean;
  onSignIn: () => void;
  loading: boolean;
  error: string;
  onClose: () => void;
  setIsOpenResetPasswordModal: (isOpen: boolean) => void;
  handleSetEmail: (email: string) => void;
}

const Login: FC<ILogin> = ({
  onChangeLoginInfo,
  disableLogin,
  onSignIn,
  loading,
  onClose,
  error,
  setIsOpenResetPasswordModal,
  handleSetEmail,
}) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
      handleSetEmail(storedEmail);
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={isMobile ? 'fit-content' : '100%'}
      justifyContent={isMobile ? '' : 'space-between'}
    >
      <Box>
        <Box marginBottom={'24px'}>
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
              onChangeLoginInfo('email', event.target.value);
            }}
            label={t('emailOrUsername')}
            isRequired={true}
            placeholder={t('emailOrUsername')}
            value={email}
            error={error}
          />
        </Box>
        <Box>
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeLoginInfo('password', event.target.value)
            }
            label={t('password')}
            isRequired={true}
            placeholder={t('password')}
            type={'password'}
            onKeyPress={onSignIn}
          />
        </Box>
        <Box display={'flex'} justifyContent={'end'} margin="8px 16px">
          <span
            style={{
              color: '#4B5563',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '24px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onClose();
              setIsOpenResetPasswordModal(true);
            }}
          >
            {t('forgotPassword')}
          </span>
        </Box>
      </Box>
      <Box
        width="100%"
        style={{
          marginTop: isMobile ? '64px' : '0px',
        }}
      >
        <CustomPrimaryButton
          style={{ width: '100%', textTransform: 'uppercase' }}
          disabled={disableLogin}
          onClick={onSignIn}
          loading={loading}
        >
          {t('login')}
        </CustomPrimaryButton>
      </Box>
    </Box>
  );
};

export default Login;
