import * as yup from 'yup';
import moment from 'moment';

export const userInfoSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  country: yup.string().required('Country is required'),
  gender: yup.string().required('Gender is required'),
  day: yup
    .number()
    .typeError('Day must be a number')
    .required('Day is required')
    .test('valid-date', 'Invalid date', function (value) {
      const { month, year } = this.parent;
      const dateString = `${year}-${month}-${value}`;
      const date = moment(dateString, 'YYYY-MM-DD');

      return date.isValid() && !date.isAfter(moment());
    }),
  month: yup
    .number()
    .typeError('Month must be a number')
    .required('Month is required')
    .min(1, 'Month must be between 1 and 12')
    .max(12, 'Month must be between 1 and 12'),
  year: yup
    .number()
    .typeError('Year must be a number')
    .required('Year is required')
    .max(new Date().getFullYear(), 'Year cannot be in the future'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  postal_code: yup.string().required('Postal Code is required'),
});