import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Container from '../common/BoxContainer/Container';
import CasinoSwitcher from './CasinoSwitcher';
import { IGameSchema } from '../../types/Game.type';
import GameSwiper from '../common/GameSwiper/GameSwiper';
import AirbetFireIcon from '../../api/ui/svgIcons/AirbetFireIcon';
import { AppRoutePath } from '../../util/appRoutePath';
import StarIcon from '@mui/icons-material/Star';
import NoCryptoBanner from '../NoCryptoBanner/NoCryptoBanner';
import ProviderSwiper from '../Provider/ProviderSwiper';
import { Box } from '@mui/material';
import GameShowIcon from '../../api/ui/svgIcons/GameShowIcon';
import TableGameIcon from '../../api/ui/svgIcons/TableGameIcon';
import SlotIcon from '../../api/ui/svgIcons/SlotIcon';
import CasinoPageGlobalSearch from './CasinoPageGlobalSearch';
import CasinoMobileSwitcher from './CasinoMobileSwitcher';
import DepositButton from '../common/Buttons/DepositButton';

interface ICasinoPage {
  trendingGames: IGameSchema[];
  recommendedGames: IGameSchema[];
  tableGames: IGameSchema[];
  slotGames: IGameSchema[];
  gameShows: IGameSchema[];
  slotsoftGames: IGameSchema[];
  slotsoftGamesLoading: boolean;
  trendingGamesLoading: boolean;
  recommendedGamesLoading: boolean;
  slotGameLoading: boolean;
  tableGameLoading: boolean;
  isUserAuthenticated: boolean;
  gameShowsLoading: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    paddingBottom: '40px',
    flexDirection: 'column',
  },
  header: {
    ...theme.general.pageHeader,
    padding: isMobile ? '0px 16px' : '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
    padding: isMobile ? '24px 0px' : '24px 20px 0px 20px',
  },
  body: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: isMobile ? '16px' : '24px 20px 0px 20px',
    gap: isMobile ? '25px' : '40px',
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    gap: '32px',
    alignItems: 'center',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      gap: '16px',
    },

    '& .search-icon': {
      fill: '#6B7280',
    },
  },
  bannerContentWrapper: {
    position: 'absolute',
    top: '50%',
    left: isMobile ? '16px' : '48px',
    transform: 'translateY(-50%)',
    maxWidth: isMobile ? '205px' : '405px',
    [theme.breakpoints.down(769)]: {
      maxWidth: isMobile ? '205px' : '300px',
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: isMobile ? '205px' : '200px',
    },

    '& h2': {
      color: '#fff',
      fontWeight: 700,
      marginBottom: isMobile ? '15px' : '18px',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      fontSize: isMobile ? '14px' : '32px',
      lineHeight: 1,

      [theme.breakpoints.down(1080)]: {
        fontSize: '25px !important',
        marginBottom: isMobile ? '15px' : '15px',
      },
      [theme.breakpoints.down(769)]: {
        marginBottom: isMobile ? '15px' : '12px',
      },
      [theme.breakpoints.down(600)]: {
        fontSize: '14px !important',
        marginBottom: isMobile ? '15px' : '8px',
      },
    },

    '& p': {
      fontWeight: 600,
      marginBottom: isMobile ? '6px' : '24px',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      color: 'rgba(255, 255, 255, 0.75)',
      fontSize: isMobile ? '8px' : '16px',
      lineHeight: isMobile ? '12px' : '24px',

      [theme.breakpoints.down(1080)]: {
        fontSize: '11px',
        lineHeight: isMobile ? '12px' : '20px',
        marginBottom: isMobile ? '6px' : '14px',
      },

      [theme.breakpoints.down(769)]: {
        fontSize: '10px',
        lineHeight: isMobile ? '12px' : '18px',
        marginBottom: isMobile ? '6px' : '10px',
      },
      [theme.breakpoints.down(600)]: {
        fontSize: '8px',
        lineHeight: isMobile ? '12px' : '12px',
        marginBottom: isMobile ? '6px' : '6px',
      },
    },

    '& button': {
      [theme.breakpoints.down(1080)]: {
        fontSize: isMobile ? '8px' : '12px !important',
        height: '32px !important',
      },
      [theme.breakpoints.down(600)]: {
        fontSize: isMobile ? '8px' : '10px !important',
        height: '22px !important',
      },
    },
  },
}));

const RenderSlotSoftIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.625C4.8225 0.625 0.625 4.8225 0.625 10C0.625 15.1775 4.8225 19.375 10 19.375C15.1775 19.375 19.375 15.1775 19.375 10C19.375 4.8225 15.1775 0.625 10 0.625ZM11.6072 9.47031V15.3125H8.39281V9.47031H5L10 4.6875L15 9.47031H11.6072Z"
      fill="#9CA3AF"
    />
  </svg>
);

const CasinoPage = ({
  slotGameLoading,
  tableGameLoading,
  recommendedGamesLoading,
  slotGames,
  tableGames,
  trendingGamesLoading,
  trendingGames,
  recommendedGames,
  isUserAuthenticated,
  gameShows,
  gameShowsLoading,
  slotsoftGames,
  slotsoftGamesLoading,
}: ICasinoPage) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Container>
          <span className={classes.headerText}>{t('header.casino')}</span>
        </Container>
      </div>
      <Container>
        {!isMobile && <CasinoSwitcher pageName={'lobby'} />}
        {isMobile && <CasinoMobileSwitcher selectedPage={'lobby'} />}
        <Box padding={isMobile ? '16px' : '24px 20px 0px 20px'}>
          <CasinoPageGlobalSearch />

          <div style={{ position: 'relative' }}>
            <img
              src={
                isMobile
                  ? '/imgs/lobby-banner-mobile.png'
                  : '/imgs/lobby-banner.png'
              }
              style={{ width: '100%' }}
            />

            <div className={classes.bannerContentWrapper}>
              <h2>{t('highRtpLobbyBannerTitle')}</h2>
              <p>{t('highRtpLobbyBannerDesc')} </p>

              <DepositButton
                style={{
                  padding: isMobile ? '4px 35px' : '8px 35px',
                  fontSize: isMobile ? '8px' : '16px',
                  height: isMobile ? '20px' : '40px',
                  boxShadow: 'initial',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => navigate(AppRoutePath.HIGHRTP())}
              >
                <span
                  style={{
                    opacity: 0.92,
                    lineHeight: isMobile ? '16px' : '24px',
                    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  {t('playNow')}
                </span>
              </DepositButton>
            </div>
          </div>

          <GameSwiper
            title={t('trendingGames')}
            icon={<AirbetFireIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={trendingGames}
            loading={trendingGamesLoading}
            otherSyle={{ marginTop: '32px' }}
          />

          <GameSwiper
            title={t('recommendedGames')}
            icon={
              <StarIcon
                style={{
                  color: '#9CA3AF',
                  width: isMobile ? '16px' : '20px',
                  height: isMobile ? '16px' : '20px',
                }}
              />
            }
            seeAllLink={AppRoutePath.GAMELIST()}
            games={recommendedGames}
            loading={recommendedGamesLoading}
          />

          <GameSwiper
            title={t('airbetMenu.slots')}
            icon={<SlotIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={slotGames}
            loading={slotGameLoading}
          />

          <GameSwiper
            title={t('airbetMenu.highRTP')}
            icon={<RenderSlotSoftIcon />}
            seeAllLink={AppRoutePath.HIGHRTP()}
            games={slotsoftGames}
            loading={slotsoftGamesLoading}
          />

          {!isMobile && (
            <NoCryptoBanner isUserAuthenticated={isUserAuthenticated} />
          )}

          <GameSwiper
            title={t('airbetMenu.tableGames')}
            icon={<TableGameIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={tableGames}
            loading={tableGameLoading}
          />
          <ProviderSwiper />

          <GameSwiper
            title={t('airbetMenu.gameShows')}
            icon={<GameShowIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAME_SHOWS()}
            games={gameShows}
            loading={gameShowsLoading}
          />
        </Box>
      </Container>
    </div>
  );
};

export default CasinoPage;
