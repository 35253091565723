import CasinoPage from '../../components/Casino/CasinoPage';
import { useEffect, useState } from 'react';
import { ICategory, IGameSchema, IProvider } from '../../types/Game.type';
import { isMobile } from 'react-device-detect';
import {
  getGames,
  getRecommendedGames,
  getTrendingGames,
} from '../../api/services/Game.api';
import { connect } from 'react-redux';

interface ICasinoContainer {
  categories: ICategory[];
  providers: IProvider[];
  isUserAuthenticated: boolean;
}

const CasinoContainer = ({
  categories,
  providers,
  isUserAuthenticated,
}: ICasinoContainer) => {
  const [trendingGames, setTrendingGames] = useState<IGameSchema[]>([]);
  const [recommendedGames, setRecommendedGames] = useState<IGameSchema[]>([]);
  const [tableGames, setTableGames] = useState<IGameSchema[]>([]);
  const [slotGames, setSlotGmaes] = useState<IGameSchema[]>([]);
  const [slotsoftGames, setSlotsoftGames] = useState<IGameSchema[]>([]);
  const [gameShows, setGameShows] = useState<IGameSchema[]>([]);
  const [trendingGamesLoading, setTrendingGamesLoading] =
    useState<boolean>(true);
  const [recommendedGamesLoading, setRecommendedGamesLoading] =
    useState<boolean>(true);
  const [slotGameLoading, setSlotGameLoading] = useState<boolean>(true);
  const [tableGameLoading, setTableGameLoading] = useState<boolean>(true);
  const [gameShowsLoading, setGameShowsLoading] = useState<boolean>(true);
  const [slotsoftGamesLoading, setSlotsoftGamesLoading] =
    useState<boolean>(true);

  const params = {
    per_page: '28',
    device: isMobile ? 'mobile' : 'desktop',
    provider: '',
    search_name: '',
    page: 1,
  };

  const getCategoryIdBySlugName = async (categorySlugName: string) => {
    const gameParamObjInfo = categories.find(
      (item: ICategory) => item.slug === categorySlugName
    );

    if (gameParamObjInfo) {
      return gameParamObjInfo.id;
    }

    return 0;
  };

  useEffect(() => {
    init();
  }, [categories]);

  useEffect(() => {
    if (providers && providers.length > 0) {
      const providerObj = providers.find(
        (w) => w.parent_provider === 'SlotSoft'
      );

      if (providerObj?.id) {
        fetchSlotSoftGames(providerObj.id.toString());
      }
    }
  }, [providers]);

  const init = async () => {
    if (categories && categories.length > 0) {
      onLoadTrendingGame();

      onLoadRecommendedGame();

      const slotGameCategoryId = await getCategoryIdBySlugName('slot');
      onLoadGames(slotGameCategoryId, setSlotGmaes, 'slot');

      const tableGameCategoryId = await getCategoryIdBySlugName('table');
      onLoadGames(tableGameCategoryId, setTableGames, 'table');

      const gameShowCategoryId = await getCategoryIdBySlugName('game-show');
      onLoadGames(gameShowCategoryId, setGameShows, 'game-show');
    }
  };

  const fetchSlotSoftGames = async (providerId: string) => {
    const slotSoftGameCategoryId = await getCategoryIdBySlugName('slots');
    onLoadGames(
      slotSoftGameCategoryId,
      setSlotsoftGames,
      'slotsoft',
      providerId
    );
  };

  const onLoadGames = (
    categoryId: number,
    setState: any,
    gameType: string,
    providerId?: string
  ) => {
    const paramsObj = {
      ...params,
      category: categoryId,
    };

    if (providerId) {
      paramsObj.provider = providerId;

      // @ts-expect-error: Adding sort property dynamically
      paramsObj.sort = 'popular';
    }

    getGames(paramsObj)
      .then((resp: any) => {
        const games = resp.data.data['games'];
        if (games) {
          setState(games.data);
        }
      })
      .finally(() => {
        if (gameType === 'slot') {
          setSlotGameLoading(false);
        }

        if (gameType === 'table') {
          setTableGameLoading(false);
        }

        if (gameType === 'game-show') {
          setGameShowsLoading(false);
        }

        if (gameType === 'slotsoft') {
          setSlotsoftGamesLoading(false);
        }
      });
  };

  const onLoadTrendingGame = () => {
    getTrendingGames()
      .then((resp: any) => {
        const games = resp.data.data;
        if (games) {
          setTrendingGames(games);
        }
      })
      .finally(() => {
        setTrendingGamesLoading(false);
      });
  };

  const onLoadRecommendedGame = () => {
    getRecommendedGames()
      .then((resp: any) => {
        const games = resp.data.data;
        if (games) {
          setRecommendedGames(games);
        }
      })
      .finally(() => {
        setRecommendedGamesLoading(false);
      });
  };

  return (
    <CasinoPage
      trendingGames={trendingGames}
      recommendedGames={recommendedGames}
      tableGames={tableGames}
      slotGames={slotGames}
      trendingGamesLoading={trendingGamesLoading}
      recommendedGamesLoading={recommendedGamesLoading}
      slotGameLoading={slotGameLoading}
      tableGameLoading={tableGameLoading}
      isUserAuthenticated={isUserAuthenticated}
      gameShows={gameShows}
      gameShowsLoading={gameShowsLoading}
      slotsoftGames={slotsoftGames}
      slotsoftGamesLoading={slotsoftGamesLoading}
    />
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.game.categories,
  providers: state.game.providers,
  isUserAuthenticated: state.user.isUserAuthorized,
});
const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(CasinoContainer);
