import { Box, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IProvider } from '../../../types/Game.type';
import { connect } from 'react-redux';
import { ChangeEvent, useCallback, useState } from 'react';
import CustomCheckBox from '../../common/CheckBox/CustomCheckBox';
import { useTranslation } from 'react-i18next';
import CustomSecondaryButton from '../../common/Buttons/CustomSecondaryButton';
import CustomInput from '../../common/Inputs/CustomInput';
import SearchIcon from '../../../api/ui/svgIcons/SearchIcon';
import { isMobile } from 'react-device-detect';
import NewIcon from '../../../api/ui/svgIcons/NewIcon';
import { SLOTSOFT } from '../../Provider/constants';

interface ICustomSortBySelect {
  selectedProviders: string[];
  onChangeProvider: (data: number) => void;
  providers: IProvider[];
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
    boxSizing: 'border-box',

    '& .MuiOutlinedInput-input': {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
    },

    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #0D121D',
      background: '#0D121D',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
      transition: 'border-color 0.3s ease-in-out, background 0.3s ease-in-out',
      borderColor: '#1F2937',

      '&:hover': {
        background: '#111827',
      },
      '&.selectOpen': {
        background: '#111827',
      },
      '& fieldset': {
        border: 'none',
      },
      '& .MuiSvgIcon-root': {
        color: '#84CC16',
        transition: 'transform 0.3s ease-in-out',
      },
    },
  },
  renderItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',

    '& span': {
      color: '#9CA3AF',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
    },

    '& .new-icon': {
      fill: 'white',
      marginLeft: 'auto',
    },
  },
  paper: {
    padding: '0px',
    borderRadius: '8px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.10)',
    marginTop: '8px',

    '& .search-icon': {
      fill: '#6B7280',
    },

    '& .MuiList-root': {
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItem: 'center',
      borderRadius: '10px',
      transition: 'background 0.2s ease-in-out, border-color 0.2s ease-in-out',
      padding: '8px',
      background: '#111827',
      border: '1px solid',
      borderColor: '#111827',
      '&:hover': {
        background: '#0D121D !important',
        borderColor: '#1F2937',
      },
      '&.Mui-selected': {
        backgroundColor: '#0D121D !important',
      },
    },

    '& .active-item': {
      background: '#0D121D !important',
    },
  },
  renderLabelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '0px',
    boxSizing: 'border-box',
    gap: '21px',
  },
  renderLabelContainerKey: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  renderLabelContainerValue: {
    color: '#84CC16',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  selectFooter: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    height: '64px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background:
      'linear-gradient(180deg, rgba(17, 24, 39, 0.00) 0%, #111827 100%)',
  },
  childrenContainer: {
    maxHeight: '280px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  emptyContainer: {
    color: '#9CA3AF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

const CustomProviderSelect = ({
  selectedProviders,
  onChangeProvider,
  providers,
}: ICustomSortBySelect) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState<string>('');

  const getCustomData = useCallback(() => {
    const reorderedProviders = [...providers];
    const index = reorderedProviders.findIndex(
      (provider) => provider.parent_provider === SLOTSOFT
    );
    if (index !== -1) {
      const [provider185] = reorderedProviders.splice(index, 1);
      reorderedProviders.unshift(provider185);
    }

    return reorderedProviders
      .filter((provider: IProvider) =>
        provider.title.toLowerCase().includes(filterValue.toLowerCase())
      )
      .map((provider: IProvider) => {
        return {
          renderer: () => (
            <Box className={classes.renderItem} key={provider.id}>
              <CustomCheckBox
                checked={selectedProviders.includes(String(provider.id))}
                onChange={() => {}}
              />
              <span>{provider.title}</span>
              {provider.parent_provider === SLOTSOFT && <NewIcon />}
            </Box>
          ),
          value: String(provider.id),
        };
      });
  }, [providers, selectedProviders, filterValue]);

  return (
    <Box
      className={classes.root}
      style={{
        minWidth: isMobile ? '' : '200px',
      }}
    >
      <Select
        multiple={true}
        value={selectedProviders}
        displayEmpty
        renderValue={() => {
          return (
            <Box className={classes.renderLabelContainer}>
              <span className={classes.renderLabelContainerKey}>
                {t('providers')}
              </span>
              <span className={classes.renderLabelContainerValue}>
                {selectedProviders.length}
              </span>
            </Box>
          );
        }}
        MenuProps={{
          PaperProps: {
            className: classes.paper,
          },
        }}
      >
        <Box>
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFilterValue(event.target.value)
            }
            size={'small'}
            value={filterValue}
            startAdornment={
              <SearchIcon size={16} pathClassName={'search-icon'} />
            }
          />
        </Box>
        <Box className={classes.childrenContainer}>
          {getCustomData().map((item: any, index: number) => {
            return (
              <MenuItem
                value={item.value}
                key={index}
                onClick={() => onChangeProvider(item.value)}
                className={`${selectedProviders.includes(item.value) && 'active-item'}`}
              >
                {item.renderer()}
              </MenuItem>
            );
          })}
        </Box>
        {selectedProviders.length > 1 && getCustomData().length > 1 && (
          <Box className={classes.selectFooter}>
            <CustomSecondaryButton
              style={{
                textTransform: 'uppercase',
                height: '32px',
              }}
              onClick={() => onChangeProvider(-1)}
            >
              {t('clear')}
            </CustomSecondaryButton>
          </Box>
        )}
        {getCustomData().length === 0 && (
          <Box className={classes.emptyContainer}>{t('noResult')}</Box>
        )}
      </Select>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  providers: state.game.providers,
});
const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(CustomProviderSelect);
