import { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CustomModal from '../common/Modal/CustomModal';
import { Box } from '@mui/material';
import DepositIcon from '../../api/ui/svgIcons/DepositIcon';
import { useTranslation } from 'react-i18next';
import BuyCryptoIcon from '../../api/ui/svgIcons/BuyCryptoIcon';
import DepositContainer from '../../containers/Account/DepositContainer';
import BuyCryptoContainer from '../../containers/Account/BuyCryptoContainer';
import { isMobile } from 'react-device-detect';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import QuestionMarkIcon from '../../api/ui/svgIcons/QuestoinMartIcon';
import DepositFiatContainer from '../../containers/Account/DepositFiatContainer';

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100%' : '857px',
    borderRadius: '12px',
    display: 'flex',
    boxSizing: 'border-box',
    background: '#111827',
    flexDirection: 'column',
    overflowY: 'auto',
    height: isMobile ? `${window.innerHeight - 61}px` : '',
  },
  header: {
    padding: '16px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: isMobile ? '#0D121D' : '',
    justifyContent: 'space-between',
    '& path': {
      fill: '#fff',
    },
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    gap: '10px',
    padding: '8px 16px 8px 12px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, transform 0.3s',
  },
  headerLine: {
    width: '100%',
    border: '1px solid #FFFFFF0D',
  },
  mobileModal: {
    position: 'fixed',
    width: '100%',
    zIndex: 99,
    top: 0,
  },
  debitCreditCardBtn: {
    cursor: 'pointer',
    borderRadius: '8px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: 'rgba(31, 41, 55, 1)',
    maxWidth: '500px',
  },
  debitCreditCardText: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface DepositModalProps {
  setIsOpenDepositModal: (isOpen: boolean) => void;
  isOpenDepositModal: boolean;
  changeGlobalDepositModalFn: (isOpen: boolean) => void;
}

type TabType = 'buyCrypto' | 'deposit_fiat' | 'deposit';

const DepositModal: FC<DepositModalProps> = ({
  setIsOpenDepositModal,
  isOpenDepositModal,
  changeGlobalDepositModalFn,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [accountType, setAccountType] = useState<TabType>('deposit');

  const handleChangeType = (type: TabType) => {
    setAccountType(type);
  };

  const goToHowToDeposit = () => {
    window.open(
      'https://6746e917056f1eb95a1123bc.kb.help/how-to-make-a-deposit/',
      '_blank'
    );
  };

  const getBody = () => {
    return (
      <Box className={classes.root}>
        <div className={classes.header}>
          {/* Header */}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {/* Header Back Icon on Mobile */}
            {isMobile && (
              <ArrowBackIosNewIcon
                onClick={() => {
                  setIsOpenDepositModal(false);
                  changeGlobalDepositModalFn(false);
                }}
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            )}

            {/* Header Deposit Tab */}
            <Box
              className={classes.headerButton}
              style={{ background: accountType === 'deposit' ? '#1F2937' : '' }}
              onClick={() => handleChangeType('deposit')}
            >
              {!isMobile && <DepositIcon size={20} />}
              <span>{t('deposit')}</span>
            </Box>

            {/* Header Deposit Fiat */}
            {/* <Box
              className={classes.headerButton}
              style={{
                background: accountType === 'deposit_fiat' ? '#1F2937' : '',
              }}
              onClick={() => handleChangeType('deposit_fiat')}
            >
              {!isMobile && <DepositIcon size={20} />}
              <span>{t('interkasa.depositFiat')}</span>
            </Box> */}

            {/* Header Debit/Credit Card Tab */}
            <Box
              className={classes.headerButton}
              style={{
                background: accountType === 'buyCrypto' ? '#1F2937' : '',
              }}
              onClick={() => handleChangeType('buyCrypto')}
            >
              {!isMobile && <BuyCryptoIcon size={20} />}
              <span>{t('BuyCrypto')}</span>
            </Box>
          </Box>
          {accountType === 'buyCrypto' && !isMobile && (
            <Box
              className={classes.debitCreditCardBtn}
              onClick={goToHowToDeposit}
            >
              <QuestionMarkIcon size={18} fill={'rgba(156, 163, 175, 1)'} />
              <span className={classes.debitCreditCardText}>
                {t('howToDepositWithDebitCreditCard')}
              </span>
            </Box>
          )}
        </div>
        <Box className={classes.headerLine} />
        {accountType === 'deposit' && <DepositContainer hideHeader={true} />}
        {accountType === 'deposit_fiat' && (
          <DepositFiatContainer hideHeader={true} />
        )}
        {accountType === 'buyCrypto' && (
          <BuyCryptoContainer hideHeader={true} />
        )}
      </Box>
    );
  };

  return isMobile ? (
    isOpenDepositModal && <Box className={classes.mobileModal}>{getBody()}</Box>
  ) : (
    <CustomModal
      open={isOpenDepositModal}
      onClose={() => {
        setIsOpenDepositModal(false);
        changeGlobalDepositModalFn(false);
      }}
      fullScreen={isMobile ? '80vh' : ''}
    >
      {getBody()}
    </CustomModal>
  );
};

export default DepositModal;
