import React, { FC } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IGameSchema } from '../../types/Game.type';
import { SLOTSOFT } from '../Provider/constants';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '189px',
    },
    buttonText: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
    },
  })
);

interface PlayDemoGameProps {
  setGameType: (value: string | null) => void;
  setIsLoginOpen: (isOpen: boolean) => void;
  gameInfo: IGameSchema | null;
}

const PlayDemoGame: FC<PlayDemoGameProps> = ({
  setGameType,
  setIsLoginOpen,
  gameInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.content}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <img
          src={`/imgs/gameDenied.png`}
          alt={'Image here'}
          style={{ width: '130px', height: '128px' }}
        />
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            color: '#FFFFFF',
            lineHeight: '24px',
            fontWeight: 600,
            fontSize: '14px',
            marginBottom: '8px',
            marginTop: '37px',
            textTransform: 'uppercase',
          }}
        >
          {t('gamingDenied')}
        </span>
        <span
          style={{
            color: '#9CA3AF',
            lineHeight: '24px',
            fontWeight: 600,
            fontSize: '12px',
          }}
        >
          {t('unfortunatelyAirBetIsNotAvailable')}
        </span>
        <span
          style={{
            color: '#9CA3AF',
            lineHeight: '24px',
            fontWeight: 600,
            fontSize: '12px',
          }}
        >
          {t('inYourRegion')}
        </span>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        flexDirection={'row'}
        justifyContent={
          gameInfo?.provider === 'Inhouse' ||
          gameInfo?.parent_provider === SLOTSOFT
            ? 'center'
            : ''
        }
        gap={'16px'}
        marginTop={'32px'}
      >
        <CustomPrimaryButton
          style={{
            textTransform: 'uppercase',
            borderRadius: '6px',
            height: '32px',
            width: '86px',
            padding: '0px',
          }}
          onClick={() => setIsLoginOpen(true)}
        >
          <span className={classes.buttonText}>{t('signUp')}</span>
        </CustomPrimaryButton>

        {gameInfo &&
          gameInfo?.provider !== 'Inhouse' &&
          gameInfo?.parent_provider !== SLOTSOFT && (
            <CustomPrimaryButton
              style={{
                textTransform: 'uppercase',
                background: '#111827',
                borderRadius: '6px',
                height: '32px',
                boxShadow: 'none',
                border: 'none',
                width: '86px',
              }}
              onClick={() => setGameType('demo')}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                <PlayArrowIcon sx={{ fontSize: '16px' }} />
                <span className={classes.buttonText}>{t('demo')}</span>
              </div>
            </CustomPrimaryButton>
          )}
      </Box>
    </Box>
  );
};
export default PlayDemoGame;
