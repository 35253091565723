import { useEffect, useRef, useState } from 'react';
import GamePage from '../../components/GamesPage/GamePage';
import { connect } from 'react-redux';
import {
  ICategory,
  IGameFilterParams,
  IGameSchema,
  IProvider,
} from '../../types/Game.type';
import { isMobile } from 'react-device-detect';
import { getGames } from '../../api/services/Game.api';
import { bindActionCreators } from 'redux';
import {
  addGameToFavourite,
  removeGameFromFavourite,
} from '../../redux/actions/gameActions';
import { useSearchParams } from 'react-router-dom';
import useDebouncedCallback from '../../hooks/useDebounce';

interface IGameContainer {
  categorySlugName: string;
  categories: ICategory[];
  providers: IProvider[];
  favourite: IGameSchema[];
  pageName: string;
  hideBlockedGameFilter?: boolean;
  hideGameProviderFilter?: boolean;
  hideGameSortByFilter?: boolean;
  isOriginalGame?: boolean;
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
  showSwitcher?: boolean;
}

const GameContainer = ({
  categories,
  providers,
  categorySlugName,
  pageName,
  isOriginalGame,
  hideGameProviderFilter,
  hideGameSortByFilter,
  hideBlockedGameFilter,
  favourite,
  addToFavoriteGameList,
  removeFromFavoriteGameList,
  showSwitcher = false,
}: IGameContainer) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchNameQuery = searchParams.get('search_name');
  const blockedQuery = searchParams.get('show_available_only');
  const providerQuery = searchParams.get('provider');
  const sortQuery = searchParams.get('sort');

  const currentPageRef = useRef<number>(1);
  const initialFilterParams: IGameFilterParams = isOriginalGame
    ? { provider: '' }
    : {
        per_page: '28',
        device: isMobile ? 'mobile' : 'desktop',
        category: 0,
        provider: '',
        sort: 'popular',
        search_name: '',
        page: 1,
      };
  const [blockedGames, setBlockedGames] = useState<string>(
    blockedQuery === '1' ? 'hide' : 'show'
  );
  const [selectedProviders, setProviders] = useState<string[]>(
    providerQuery ? [providerQuery] : []
  );
  const [searchValue, setSearchValue] = useState<string>(searchNameQuery || '');
  const [sortBy, setSortBy] = useState<string>(sortQuery || 'popular');

  const [filterParams, setFilterParams] =
    useState<IGameFilterParams>(initialFilterParams);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [games, setGames] = useState<IGameSchema[]>([]);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
  const [categorySlug, setCategorySlug] = useState<string | null>(null);

  useEffect(() => {
    if (!isOriginalGame && !providerQuery) {
      const gameShowObj = categories.find(
        (item: ICategory) => item.slug === categorySlugName
      );
      if (gameShowObj) {
        setCategorySlug(gameShowObj.slug);
        setFilterParams({
          ...filterParams,
          category: gameShowObj.id,
        });
      }
    }
  }, [categories]);

  useEffect(() => {
    if (isOriginalGame && !providerQuery) {
      const id: number | undefined = providers.find(
        (provider) => provider.slug === 'inhouse'
      )?.id;
      if (id) {
        setFilterParams({
          ...filterParams,
          provider: String(id),
        });
      }
    }
  }, [providers]);

  useEffect(() => {
    if (!isOriginalGame && providerQuery) {
      if (providers && categories) {
        const gameShowObj = categories.find(
          (item: ICategory) => item.slug === categorySlugName
        );
        const providerId: number | undefined = providers.find(
          (provider: IProvider) => provider.id === Number(providerQuery)
        )?.id;
        if (gameShowObj && providerId) {
          setCategorySlug(gameShowObj.slug);
          setFilterParams({
            ...filterParams,
            category: gameShowObj.id,
            provider: String(providerId),
          });
          setProviders([String(providerId)]);
        }
      }
    }
  }, [providers, categories, providerQuery]);

  useEffect(() => {
    if (!isOriginalGame && filterParams.category) {
      onLoadGame();
    }

    if (isOriginalGame && filterParams.provider) {
      onLoadGame();
    }
  }, [filterParams, searchParams]);

  const onLoadGame = (): void => {
    setLoading(true);

    const obj = {
      search_name: searchNameQuery,
      show_available_only: blockedQuery,
      provider: providerQuery,
      sort: sortQuery,
    };

    const newObj: { [key: string]: string | null } = {};

    for (const key in obj) {
      if (
        obj[key as keyof typeof obj] !== null &&
        obj[key as keyof typeof obj] !== undefined
      ) {
        newObj[key] = obj[key as keyof typeof obj];
      }
    }

    getGames({ ...filterParams, ...newObj })
      .then((resp: any) => {
        const games = resp.data.data['games'];
        if (games) {
          setTotalResult(games.total);
          setGames(games.data);
        }
        currentPageRef.current = 1;
      })
      .finally(() => {
        setLoading(false);
        if (firstLoading) {
          setFirstLoading(false);
        }
      });
  };

  const setFiltersHandler = (value: string) => {
    setFilterParams({
      ...filterParams,
      search_name: value,
    });

    setSearchParams((prev) => {
      if (value) {
        prev.set('search_name', value);
      } else {
        prev.delete('search_name');
      }
      return prev;
    });
  };

  const debouncedSetFiltersHandler = useDebouncedCallback(
    setFiltersHandler,
    300
  );

  const onChangeSearchValue = (value: string): void => {
    setSearchValue(value);
    debouncedSetFiltersHandler(value);
  };

  const onChangeBlockedGames = (value: string): void => {
    setBlockedGames(value);
    const currentParams: IGameFilterParams = {
      ...filterParams,
      show_available_only: 1,
    };

    setSearchParams((prev) => {
      if (value === 'show') {
        prev.delete('show_available_only');
      } else {
        prev.set('show_available_only', '1');
      }
      return prev;
    });

    if (value === 'show') {
      delete currentParams.show_available_only;
      setFilterParams(currentParams);
    } else {
      setFilterParams(currentParams);
    }
  };

  const onChangeProviders = (providerId: number): void => {
    if (providerId === -1) {
      setProviders([]);
      setSearchParams((prev) => {
        prev.delete('provider');
        return prev;
      });
    } else {
      const isAlreadyInArray = selectedProviders.includes(String(providerId));
      const result = isAlreadyInArray
        ? [
            ...selectedProviders.filter(
              (item: string) => item !== String(providerId)
            ),
          ]
        : [...selectedProviders, String(providerId)];

      setProviders(result);
      setFilterParams({
        ...filterParams,
        provider: result.join(','),
      });

      setSearchParams((prev) => {
        if (result.length > 0) {
          prev.set('provider', result.join(','));
        } else {
          prev.delete('provider');
        }
        return prev;
      });
    }
  };

  const onChangeSort = (value: string): void => {
    setSortBy(value);
    setFilterParams({
      ...filterParams,
      sort: value,
    });

    setSearchParams((prev) => {
      if (value) {
        prev.set('sort', value);
      } else {
        prev.delete('sort');
      }
      return prev;
    });
  };

  const loadMoreGames = (): void => {
    currentPageRef.current = currentPageRef.current + 1;

    if (filterParams.category) {
      setLoadMoreLoading(true);

      const obj = {
        search_name: searchNameQuery,
        show_available_only: blockedQuery,
        provider: providerQuery,
        sort: sortQuery,
      };

      const newObj: { [key: string]: string | null } = {};

      for (const key in obj) {
        if (
          obj[key as keyof typeof obj] !== null &&
          obj[key as keyof typeof obj] !== undefined
        ) {
          newObj[key] = obj[key as keyof typeof obj];
        }
      }

      getGames({ ...filterParams, ...newObj, page: currentPageRef.current })
        .then((resp: any) => {
          const gamesData = resp.data.data['games'];
          if (gamesData) {
            setTotalResult(gamesData.total);
            setGames([...games, ...gamesData.data]);
          }
        })
        .finally(() => {
          setLoadMoreLoading(false);
        });
    }
  };

  const onClearParams = () => {
    if (isOriginalGame) {
      setFilterParams({
        ...initialFilterParams,
        provider: filterParams.provider,
      });
    } else {
      setFilterParams({
        ...initialFilterParams,
        category: filterParams.category,
      });
    }
    setSearchValue('');
    setBlockedGames('show');
    setProviders([]);
    setSortBy('popular');
  };

  return (
    <GamePage
      onChangeSearchValue={onChangeSearchValue}
      onChangeBlockedGames={onChangeBlockedGames}
      onChangeProviders={onChangeProviders}
      onChangeSort={onChangeSort}
      currentSearchValue={searchValue}
      selectedBlockGame={blockedGames}
      selectedProviders={selectedProviders}
      selectedSortBy={sortBy}
      totalResult={totalResult}
      games={games}
      loading={loading}
      loadMoreLoading={loadMoreLoading}
      loadMoreGames={loadMoreGames}
      firstLoading={firstLoading}
      onClearParams={onClearParams}
      pageName={pageName}
      isOriginalGame={isOriginalGame}
      hideGameProviderFilter={hideGameProviderFilter}
      hideGameSortByFilter={hideGameSortByFilter}
      hideBlockedGameFilter={hideBlockedGameFilter}
      favourite={favourite}
      addToFavoriteGameList={addToFavoriteGameList}
      removeFromFavoriteGameList={removeFromFavoriteGameList}
      showSwitcher={showSwitcher}
      categorySlug={categorySlug}
    />
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.game.categories,
  providers: state.game.providers,
  favourite: state.game.favourite,
});
const dispatchToProps = (dispatch: any) => ({
  addToFavoriteGameList: bindActionCreators(addGameToFavourite, dispatch),
  removeFromFavoriteGameList: bindActionCreators(
    removeGameFromFavourite,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(GameContainer);
