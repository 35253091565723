import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import CustomModal from '../common/Modal/CustomModal';
import { Box } from '@mui/material';
import WelcomeBonusContent from './WelcomeBonusContent';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100%' : '592px',
    height: !isMobile
      ? ''
      : window.innerHeight < 660
        ? `${window.innerHeight - 50}px`
        : '',
    borderRadius: '12px',
    display: 'flex',
    boxSizing: 'border-box',
    overflowY: 'auto',
    background: '#111827',
    minWidth: '340px !important',
  },
}));

interface WelcomeBonusModalProps {
  setOpenWelcomeBonus: (isOpen: boolean) => void;
  openWelcomeBonus: boolean;
}

const WelcomeBonusModal: FC<WelcomeBonusModalProps> = ({
  setOpenWelcomeBonus,
  openWelcomeBonus,
}) => {
  const classes = useStyles();

  return (
    <CustomModal
      open={openWelcomeBonus}
      customStyle={isMobile ? { width: '96%', top: '52%' } : {}}
      onClose={() => setOpenWelcomeBonus(false)}
    >
      <Box className={classes.root}>
        <WelcomeBonusContent setOpenWelcomeBonus={setOpenWelcomeBonus} />
      </Box>
    </CustomModal>
  );
};

export default WelcomeBonusModal;
