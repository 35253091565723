import { IGameSchema } from '../../types/Game.type';
import CustomModal from '../common/Modal/CustomModal';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import CustomInput from '../common/Inputs/CustomInput';
import React, { ChangeEvent } from 'react';
import GameCard from '../GameCard/GameCard';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../api/ui/svgIcons/SearchIcon';
import CustomShowOrHideSelect from '../GamesPage/common/CustomShowOrHideSelect';
import CustomProviderSelect from '../GamesPage/common/CustomProviderSelect';
import CustomSortBySelect from '../GamesPage/common/CustomSortBySelect';
import LoadMoreGames from '../GamesPage/common/LoadMoreGames';
import cn from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: isMobile ? '100%' : '1180px',
    boxSizing: 'border-box',
    background: '#111827',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #1F2937',
    gap: '10px',
    transition: 'width 0.3s ease-in-out',
    [theme.breakpoints.down(1200)]: {
      width: !isMobile ? '850px' : '100%',
    },
    [theme.breakpoints.down(900)]: {
      width: !isMobile ? '690px' : '100%',
    },
  },
  rootMobile: {
    width: '360px',
    height: '100vh',
    boxSizing: 'border-box',
    background: '#111827',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #1F2937',
    gap: '10px',
    transition: 'width 0.3s ease-in-out',
    [theme.breakpoints.down(1200)]: {
      width: '850px',
    },
    [theme.breakpoints.down(900)]: {
      width: '100%',
    },
  },
  header: {
    padding: '8px 16px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    '& .MuiSvgIcon-root': {
      color: '#9CA3AF',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#fff',
    textTransform: 'uppercase',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bodyForMobile: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  secondaryText: {
    color: '#9CA3AF',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  primaryText: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  innerGameBox: {
    height: '100%',
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'auto',
    paddingTop: '20px',
  },
  innerGameBoxMobile: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    marginTop: '20px',
    height: '100vh',
    justifyContent: 'center',
  },
  closeIcon: {
    fontSize: '20px',
    cursor: 'pointer',
    color: 'rgba(156, 163, 175, 1)',
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    alignItems: 'center',
    gap: '32px',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      gap: '16px',
    },

    '& .search-icon': {
      fill: '#6B7280',
    },
  },
  noResultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  modalForMobileDevice: {
    position: 'fixed',
    width: '100%',
    left: 0,
    zIndex: 99,
    top: 0,
  },
}));

interface IGlobalSearch {
  games: IGameSchema[];
  loading: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  searchValue: string;
  clearSearchValue: () => void;
  onChangeBlockedGames: (value: string) => void;
  selectedBlockGame: string;
  onChangeProviders: (provider: number) => void;
  selectedProviders: string[];
  selectedSortBy: string;
  onChangeSort: (value: string) => void;
  onChangeSearchValue: (value: string) => void;
  totalResult: number;
  loadMoreGames: () => void;
  loadMoreLoading: boolean;
}

const GlobalSearch = ({
  games,
  loading,
  setOpen,
  open,
  searchValue,
  clearSearchValue,
  onChangeBlockedGames,
  selectedBlockGame,
  onChangeProviders,
  selectedProviders,
  selectedSortBy,
  onChangeSort,
  onChangeSearchValue,
  totalResult,
  loadMoreGames,
  loadMoreLoading,
}: IGlobalSearch) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getBody = () => {
    return (
      <Box className={!isMobile ? classes.root : cn(classes.rootMobile)}>
        <Box className={classes.header}>
          <span className={classes.headerText}>{t('searchForGames')}</span>
          <span
            onClick={() => setOpen(false)}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon />
          </span>
        </Box>

        <div className={classes.filterContainer}>
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeSearchValue(event.target.value)
            }
            secondary={true}
            value={searchValue}
            autoFocus={true}
            startAdornment={<SearchIcon pathClassName={'search-icon'} />}
            endAdornment={
              searchValue && (
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={clearSearchValue}
                />
              )
            }
          />

          <Box display={'flex'} gap={'18px'} width={'100%'}>
            {!isMobile && (
              <CustomShowOrHideSelect
                selectedBlockGame={selectedBlockGame}
                onChangeBlockedGames={onChangeBlockedGames}
              />
            )}
            <CustomProviderSelect
              onChangeProvider={onChangeProviders}
              selectedProviders={selectedProviders}
            />
            <CustomSortBySelect
              selectedSortByValue={selectedSortBy}
              onChangeSortBy={onChangeSort}
            />
          </Box>
        </div>

        <Box
          className={
            !isMobile ? classes.body : cn(classes.bodyForMobile, 'pb-[150px]')
          }
        >
          <Box
            style={{
              opacity: loading ? 0.3 : 1,
              transition: 'opacity 0.3s ease-int-out',
              maxHeight: !isMobile ? 'calc(100vh - 400px)' : '100%',
              overflow: 'auto',
            }}
          >
            {games.length ? (
              <Box
                className={
                  !isMobile ? classes.innerGameBox : classes.innerGameBoxMobile
                }
              >
                {games.map((game: IGameSchema, index: number) => (
                  <Box key={index} onClick={() => setOpen(false)}>
                    <GameCard
                      gameName={game.game_title}
                      gameProvider={game.provider}
                      backgroundImageUrl={game.image || ''}
                      isFavorite={false}
                      isAvailable={!game.coming_soon && !game.is_restricted}
                      comingSoon={!!game.coming_soon}
                      addToFavorites={() => {}}
                      removeFromFavorite={() => {}}
                      startPlaying={() => navigate(`/games/${game.slug}`)}
                      isMobile={isMobile}
                      agregator_image={game.agregator_image}
                      categorySlug={'all'}
                      index={index}
                      gameId={game.game_id}
                    />
                  </Box>
                ))}
                {Array.from({
                  length: isMobile ? 3 : 7,
                }).map((_, index) => (
                  <Box key={`${index}_empty_card`} width={'107px'} />
                ))}
                {isMobile && games.length !== totalResult && (
                  <LoadMoreGames
                    currentGamesCount={games.length}
                    totalGamesCount={totalResult}
                    loadMoreGames={loadMoreGames}
                    loadMoreLoading={loadMoreLoading}
                  />
                )}
              </Box>
            ) : (
              <Box className={classes.noResultWrapper}>
                <span className={classes.primaryText}>{t('noResult')}</span>
                <span className={classes.secondaryText}>
                  {t('noResultInSearch')}
                </span>
              </Box>
            )}
          </Box>
          {games.length && !isMobile && games.length !== totalResult && (
            <LoadMoreGames
              currentGamesCount={games.length}
              totalGamesCount={totalResult}
              loadMoreGames={loadMoreGames}
              loadMoreLoading={loadMoreLoading}
            />
          )}
        </Box>
      </Box>
    );
  };

  return isMobile ? (
    open && <Box className={classes.modalForMobileDevice}>{getBody()}</Box>
  ) : (
    <CustomModal
      fullScreen={{ width: isMobile && '100%' }}
      open={open}
      onClose={() => setOpen(false)}
    >
      {getBody()}
    </CustomModal>
  );
};

export default GlobalSearch;
