import * as React from 'react';
import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Backdrop, Fade } from '@mui/material';

interface CustomModal {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  fullScreen?: any;
  customStyle?: any;
  className?: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const CustomModal = ({
  children,
  open,
  onClose,
  fullScreen,
  customStyle = {},
  className,
}: CustomModal) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
      disableAutoFocus={true}
      className={className}
    >
      <Fade mountOnEnter unmountOnExit in={open}>
        <Box
          sx={style}
          width={fullScreen ? fullScreen : 'auto'}
          style={{
            ...customStyle,
          }}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
