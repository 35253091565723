import Snackbar from '../components/common/Snackbar/Snackbar';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayoutWrapper from './Layout/MainLayoutWrapper';
import AppRoutes from './AppRoutes';
import { useEffect, useState } from 'react';
import {
  getLocalStorageValue,
  getUserLanguage,
  setLocalStorageValue,
} from '../util/appUtil';
import { bindActionCreators } from 'redux';
import {
  changeAccessToken,
  changeCountryRestricted,
  changeLanguage,
  changeRanksInfo,
  changeUserAuthorization,
  changeUserInfo,
} from '../redux/actions/userActions';
import { connect } from 'react-redux';
import { registerUser } from '../api/airbet.socket';

import {
  getFavoriteGame,
  getGameCategories,
  getProviders,
} from '../api/services/Game.api';
import {
  changeGameCategories,
  changeInitFavouriteGame,
  changeProviders,
} from '../redux/actions/gameActions';
import { ICategory, IGameSchema, IProvider } from '../types/Game.type';
import { isCountryRestricted } from '../api/services/Country.api';
import { IAccessToken, IRankInfo, IUserInfo } from '../types/common.type';
import { getRanks, getUserInfo } from '../api/services/User.api';
import { getGeneralSettings } from '../api/services/Setting.api';
import { changeSettings } from '../redux/actions/settingActions';

interface IApplicationContainer {
  changeUserAuthorizationFunction: (isAuthorized: boolean) => void;
  changeGameCategories: (data: ICategory[]) => void;
  changeProviders: (data: IProvider[]) => void;
  changeLanguage: (language: string) => void;
  changeCountryRestricted: (restricted: boolean) => void;
  setFavouriteGameList: (data: IGameSchema[]) => void;
  changeUserInfo: (data: IUserInfo) => void;
  changeRanksInfo: (data: IRankInfo[]) => void;
  changeSettingInfo: (data: any) => void;
  isUserAuthorized: boolean;
  changeUserAccessToken: (data: IAccessToken) => void;
}

const ApplicationContainer = ({
  changeUserAuthorizationFunction,
  changeGameCategories,
  changeProviders,
  changeLanguage,
  changeCountryRestricted,
  setFavouriteGameList,
  changeUserInfo,
  changeRanksInfo,
  changeSettingInfo,
  isUserAuthorized,
  changeUserAccessToken,
}: IApplicationContainer) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    checkIfUserIsAuthorized();
    checkPlayerNumbersByGame();
    setGameCategories();
    setProviders();
    setLanguage();
    setRestrictedCountry();
    setSettings();
    setRanks();
  }, []);

  useEffect(() => {
    if (isUserAuthorized) {
      setFavouriteGame();
      const userId = getLocalStorageValue('userId', 0);
      const token = getLocalStorageValue('token', '');
      registerUser(token, userId);
    }
  }, [isUserAuthorized]);

  const setFavouriteGame = () => {
    getFavoriteGame()
      .then((reps: any) => {
        setFavouriteGameList(reps.data.data);
      })
      .catch(() => {});
  };

  const setGameCategories = () => {
    getGameCategories().then((resp: any) => {
      if (resp.data.data) {
        changeGameCategories(resp.data.data);
      }
    });
  };

  const setProviders = (): void => {
    getProviders().then((resp: any) => {
      if (resp.data.data) {
        changeProviders(resp.data.data);
      }
    });
  };

  const setLanguage = (): void => {
    changeLanguage(getUserLanguage());
  };

  const setRestrictedCountry = (): void => {
    isCountryRestricted()
      .then((resp) => {
        changeCountryRestricted(resp.data.data.is_restricted);
      })
      .catch(() => {
        changeCountryRestricted(false);
      });
  };

  const setRanks = () => {
    getRanks()
      .then((resp: any) => {
        if (resp.data.data) {
          changeRanksInfo(resp.data.data);
        }
      })
      .catch(() => {});
  };

  const checkIfUserIsAuthorized = () => {
    const isUserAuthorized = Boolean(
      getLocalStorageValue('isAuthorized', false)
    );
    const userToken = Boolean(getLocalStorageValue('token', ''));
    const isTokenExpired = getLocalStorageValue('token_expire_at', new Date());
    const result = Boolean(
      isUserAuthorized && userToken /*&& new Date(isTokenExpired) > new Date()*/
    );
    changeUserAuthorizationFunction(result);

    if (result) {
      const token = getLocalStorageValue('token', '');

      getUserInfo()
        .then((resp: any) => {
          if (resp.data.data) {
            const data: IUserInfo = {
              access_Token: {
                token: token,
                expire_at: isTokenExpired,
              },
              player: resp.data.data,
            };
            changeUserInfo(data);
            setLocalStorageValue('userInfo', JSON.stringify(data));
          }
        })
        .catch(() => {
          changeUserAuthorizationFunction(false);
          changeUserAccessToken({
            expire_at: '',
            token: '',
          });
          setLocalStorageValue('isAuthorized', false);
          setLocalStorageValue('token_expire_at', '');
          setLocalStorageValue('userId', '');
          setLocalStorageValue('token', '');
          setLocalStorageValue('userInfo', '');
        });
    }

    setLoading(false);

    /*    const interval = setInterval(() => {
          if (result) {
            const differenceInMinutes =
              (new Date(isTokenExpired).getTime() - new Date().getTime()) /
              (1000 * 60);
    
            if (differenceInMinutes <= 5) {
              RefreshToken()
                .then(() => {
                  console.log('here go refresh token api');
                })
                .catch(() => {});
            }
          }
        }, 60000);
    
        if (!result) {
          clearInterval(interval);
        }
    
        setLoading(false);
        return () => clearInterval(interval);*/
  };

  function checkPlayerNumbersByGame() {
    const savedDate = getLocalStorageValue('playersNumberDate', null);
    if (savedDate) {
      const currentDate = new Date();
      const oldDate = new Date(savedDate);

      const differenceInMilliseconds =
        currentDate.getTime() - oldDate.getTime();
      const differenceInMinutes = Math.floor(
        differenceInMilliseconds / (1000 * 60)
      );
      if (differenceInMinutes >= 3) {
        setLocalStorageValue('playersNumberDate', new Date());
        setLocalStorageValue('updatePlayersNumberDate', true);
      } else {
        setLocalStorageValue('updatePlayersNumberDate', false);
      }
    } else {
      setLocalStorageValue('playersNumberDate', new Date());
      setLocalStorageValue('updatePlayersNumberDate', true);
    }
  }

  const setSettings = () => {
    getGeneralSettings()
      .then((response: any) => {
        if (response && response.data.data) {
          changeSettingInfo(response.data.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Snackbar>
      <Router>
        <MainLayoutWrapper mainLoading={loading}>
          <AppRoutes isUserAuthorized={isUserAuthorized} />
        </MainLayoutWrapper>
      </Router>
    </Snackbar>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthorized: state.user.isUserAuthorized,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserAuthorizationFunction: bindActionCreators(
    changeUserAuthorization,
    dispatch
  ),
  changeGameCategories: bindActionCreators(changeGameCategories, dispatch),
  changeProviders: bindActionCreators(changeProviders, dispatch),
  changeLanguage: bindActionCreators(changeLanguage, dispatch),
  changeCountryRestricted: bindActionCreators(
    changeCountryRestricted,
    dispatch
  ),
  setFavouriteGameList: bindActionCreators(changeInitFavouriteGame, dispatch),
  changeUserInfo: bindActionCreators(changeUserInfo, dispatch),
  changeRanksInfo: bindActionCreators(changeRanksInfo, dispatch),
  changeSettingInfo: bindActionCreators(changeSettings, dispatch),
  changeUserAccessToken: bindActionCreators(changeAccessToken, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(ApplicationContainer);
