import PersonalVerification from '../../components/Settings/PersonalVerification/PersonalVerification';
import { connect } from 'react-redux';
import { IUserInfo } from '../../types/common.type';
import { bindActionCreators } from 'redux';
import { changeUserVerificationStatus } from '../../redux/actions/userActions';

export interface IPlayerAccountInfo {
  playerInfo: IUserInfo;
  onClose: () => void;
  changeUserVerificationStatusFn: (key: string, status: string) => void;
}

const PersonalVerificationContainer = ({
  playerInfo,
  onClose,
  changeUserVerificationStatusFn,
}: IPlayerAccountInfo) => {
  return (
    <PersonalVerification
      playerInfo={playerInfo}
      changeUserVerificationStatusFn={changeUserVerificationStatusFn}
      onClose={onClose}
    />
  );
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserVerificationStatusFn: bindActionCreators(
    changeUserVerificationStatus,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  dispatchToProps
)(PersonalVerificationContainer);
