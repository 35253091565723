import React, { FC } from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import LobbyIconForMobile from '../../api/ui/svgIcons/LobbyIconForMobile';
import WalletIcon from '../../api/ui/svgIcons/WalletIcon';
import { makeStyles } from '@mui/styles';
import AccountIconForMobile from '../../api/ui/svgIcons/AccountIconForMobile';
import { changeGlobalUserLoginModalOpen } from '../../redux/actions/userActions';
import { connect, useDispatch } from 'react-redux';
import SportIcon from '../../api/ui/svgIcons/SportIcon';
import CherryIcon from '../../api/ui/svgIcons/CherryIcon';

const useStyles = makeStyles(() => ({
  flexContainer: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      height: '60px',
      position: 'relative',
      borderTop: '1px solid #1F2937',
    },
    '& .MuiButtonBase-root': {
      flex: 1,
      minHeight: '0px',
      minWidth: '0px',
      padding: '12px 0px 12px 0px',
    },
  },
  menuText: {
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
  },
}));

interface BottomMenuMobileProps {
  isUserAuthenticated: boolean;
  value: string;
  setValue: (value: string) => void;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const BottomMenuMobile: FC<BottomMenuMobileProps> = ({
  isUserAuthenticated,
  value,
  setValue,
}: BottomMenuMobileProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (
      !isUserAuthenticated &&
      (newValue === 'wallet' || newValue === 'account')
    ) {
      dispatch(changeGlobalUserLoginModalOpen(true));
    } else {
      setValue(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        zIndex: 99,
        background: 'rgb(15 23 41)',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          TabIndicatorProps={{
            sx: {
              bgcolor: '#1D4ED8',
              height: '2px',
              position: 'absolute',
              top: '0px',
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.flexContainer}
        >
          <Tab
            label={<span className={classes.menuText}>{t('menu')}</span>}
            icon={
              <MenuIcon
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            }
            style={{ color: value === 'menu' ? '#FFF' : '#9CA3AF' }}
            {...a11yProps(0)}
            disableRipple
            value={'menu'}
          />
          <Tab
            label={
              <span className={classes.menuText}>{t('airbetMenu.lobby')}</span>
            }
            icon={
              <LobbyIconForMobile
                size={16}
                fill={value === 'lobby' ? '#FFF' : '#9CA3AF'}
              />
            }
            style={{ color: value === 'lobby' ? '#FFF' : '#9CA3AF' }}
            {...a11yProps(0)}
            disableRipple
            value={'lobby'}
          />
          <Tab
            label={<span className={classes.menuText}>{t('casino')}</span>}
            icon={
              <CherryIcon
                size={16}
                fill={value === 'casino' ? '#FFF' : '#9CA3AF'}
              />
            }
            style={{
              color: value === 'casino' ? '#FFF' : '#9CA3AF',
              justifyContent: 'space-between',
            }}
            {...a11yProps(0)}
            disableRipple
            value={'casino'}
          />
          <Tab
            label={<span className={classes.menuText}>{t('sport')}</span>}
            icon={
              <SportIcon
                size={16}
                fill={value === 'sport' ? '#FFF' : '#9CA3AF'}
              />
            }
            style={{
              color: value === 'sport' ? '#FFF' : '#9CA3AF',
            }}
            {...a11yProps(0)}
            disableRipple
            value={'sport'}
          />
          <Tab
            label={<span className={classes.menuText}>{t('wallet')}</span>}
            icon={
              <WalletIcon
                size={16}
                fill={value === 'wallet' ? '#FFF' : '#9CA3AF'}
              />
            }
            style={{ color: value === 'wallet' ? '#FFF' : '#9CA3AF' }}
            {...a11yProps(0)}
            disableRipple
            value={'wallet'}
          />
          {/* <Tab
            label={<span className={classes.menuText}>{t('chat')}</span>}
            icon={
              <ChatIcon
                size={16}
                fill={value === 'chat' ? '#FFF' : '#9CA3AF'}
              />
            }
            style={{ color: value === 'chat' ? '#FFF' : '#9CA3AF' }}
            {...a11yProps(0)}
            disableRipple
            value={'chat'}
          />*/}
          <Tab
            label={<span className={classes.menuText}>{t('account')}</span>}
            icon={
              <AccountIconForMobile
                size={20}
                fill={value === 'account' ? '#FFF' : '#9CA3AF'}
              />
            }
            style={{ color: value === 'account' ? '#FFF' : '#9CA3AF' }}
            {...a11yProps(0)}
            disableRipple
            value={'account'}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(BottomMenuMobile);
