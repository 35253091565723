import Login from '../../components/Authorization/Login';
import { useRef, useState } from 'react';
import { setLocalStorageValue } from '../../util/appUtil';
import { SignIn } from '../../api/services/Auth.api';
import { ISignIn } from '../../types/Auth.type';
import {
  IAccessToken,
  IErrorResponse,
  IUserInfo,
} from '../../types/common.type';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeAccessToken,
  changeUserAuthorization,
  changeUserInfo,
} from '../../redux/actions/userActions';

interface ILoginContainer {
  onClose: () => void;
  changeUserAuthorizationFunction: (isAuthorized: boolean) => void;
  changeUserAccessToken: (data: IAccessToken) => void;
  changeUserInfo: (data: IUserInfo) => void;
  setIsOpenResetPasswordModal: (isOpen: boolean) => void;
}

const LoginContainer = ({
  changeUserAuthorizationFunction,
  changeUserAccessToken,
  onClose,
  changeUserInfo,
  setIsOpenResetPasswordModal,
}: ILoginContainer) => {
  const loginInfoRef = useRef<ISignIn>({
    email: '',
    password: '',
  });
  const [disableLogin, setDisableLogin] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const onSignIn = () => {
    setLoading(true);
    SignIn(loginInfoRef.current)
      .then((res: any) => {
        changeUserAuthorizationFunction(true);
        changeUserAccessToken(res.data.data.access_token);
        changeUserInfo(res.data.data);
        setLocalStorageValue('userInfo', JSON.stringify(res.data.data));
        setLocalStorageValue('isAuthorized', true);
        setLocalStorageValue(
          'token_expire_at',
          res.data.data.access_token.expire_at
        );
        setLocalStorageValue('userId', res.data.data.player.id);
        setLocalStorageValue('token', res.data.data.access_token.token);
        setLocalStorageValue('userEmail', loginInfoRef.current.email); // Store email in localStorage
        onClose();
      })
      .catch((error: IErrorResponse) => {
        setError(error.response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeLoginInfo = (key: string, value: string): void => {
    loginInfoRef.current = { ...loginInfoRef.current, [key]: value };
    checkValidation();
  };

  const handleSetEmail = (email: string) => {
    onChangeLoginInfo('email', email);
  };

  const checkValidation = () => {
    setDisableLogin(
      Boolean(
        !(
          loginInfoRef.current.password.trim() &&
          loginInfoRef.current.email.trim()
        )
      )
    );
  };

  return (
    <Login
      onChangeLoginInfo={onChangeLoginInfo}
      disableLogin={disableLogin}
      onSignIn={onSignIn}
      loading={loading}
      onClose={onClose}
      error={error}
      handleSetEmail={handleSetEmail}
      setIsOpenResetPasswordModal={setIsOpenResetPasswordModal}
    />
  );
};

const mapStateToProps = (state: any) => ({
  access_token: state.user.access_token,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserAccessToken: bindActionCreators(changeAccessToken, dispatch),
  changeUserInfo: bindActionCreators(changeUserInfo, dispatch),
  changeUserAuthorizationFunction: bindActionCreators(
    changeUserAuthorization,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(LoginContainer);
