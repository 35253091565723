import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import HeaderButtonContainer from '../common/ButtonContainer/HeaderButtonContainer';
import ChatIcon from '../../api/ui/svgIcons/ChatIcon';
import { makeStyles } from '@mui/styles';
import AirbetDepositIcon from '../../api/ui/svgIcons/AirbetDepositIcon';
import DepositButton from '../common/Buttons/DepositButton';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '../../api/ui/svgIcons/SearchIcon';
import NotificationIcon from '../../api/ui/svgIcons/NotificationIcon';
import AppHeaderAccountInfoMenu from './AppHeaderAccountInfoMenu';
import { socket, useNotificationUpdates } from '../../api/airbet.socket';
import React, { useEffect, useState } from 'react';
import { INotification } from '../../types/common.type';
import { MarkAllAsRead } from '../../api/services/Notification.api';
import GlobalSearchContainer from '../../containers/GlobalSearch/GlobalSearchContainer';
import { isMobile } from 'react-device-detect';
import BonusPopover from '../Bonus/BonusPopover';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeBalance } from '../../redux/actions/userActions';
import {
  firstDepositGoogleManagerHelper,
  generalDepositGoogleManagerHelper,
} from '../../util/appUtil';

const useStyles = makeStyles(() => ({
  depositContainer: {
    display: 'flex',
    padding: isMobile ? '0px 4px' : '4px 4px 4px 8px',
    alignItems: 'center',
    gap: '16px',
    borderRadius: '12px',
    background: '#030712',
    marginRight: '12px',
  },
  depositAmount: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#ffffff',
  },
  iconSmall: {
    width: '16px',
    height: '16px',
    color: '#ffffff',
  },
  newNotification: {
    background: '#84CC16',
    width: '6px',
    height: '6px',
    borderRadius: '100%',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  userAccountBtn: {
    width: '40px',
    height: '40px',
  },
}));

interface IAppHeaderAuthorizedContent {
  isChatOpen: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
  isNotificationOpen: boolean;
  setIsNotificationOpen: (isOpen: boolean) => void;
  setIsOpenDepositModal: (isOpen: boolean) => void;
  playerInfo: any;
  updateBalance: (balance: number) => void;
}

const AppHeaderAuthorizedContent = ({
  isChatOpen,
  setIsChatOpen,
  isNotificationOpen,
  setIsNotificationOpen,
  setIsOpenDepositModal,
  playerInfo,
  updateBalance,
}: IAppHeaderAuthorizedContent) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const socketNotification = useNotificationUpdates();
  const [newNotification, setNewNotification] = useState<boolean>(false);
  const [openGlobalSearch, setOpenGlobalSearch] = useState<boolean>(false);
  const [balance, setBalance] = useState<any>(null);

  useEffect(() => {
    socket.on('balance', handleBalanceUpdate);

    return () => {
      socket.off('balance', handleBalanceUpdate);
    };
  }, []);

  useEffect(() => {
    if (playerInfo && playerInfo.player && playerInfo.player.balance) {
      setBalance(playerInfo.player.balance);
    }
  }, [playerInfo]);

  const handleBalanceUpdate = (balance: any) => {
    if (balance && balance.balance) {
      if (balance.type === 'deposit') {
        if (playerInfo.player.deposit_count === 0) {
          firstDepositGoogleManagerHelper(
            playerInfo.player.id,
            playerInfo.player.register_country,
            balance.amount
          );
        } else {
          generalDepositGoogleManagerHelper(
            playerInfo.player.id,
            playerInfo.player.register_country,
            balance.amount
          );
        }
      }
      setBalance(balance.balance);
      updateBalance(balance.balance);
    }
  };

  useEffect(() => {
    if (socketNotification) {
      if (Array.isArray(socketNotification)) {
        setNewNotification(
          socketNotification.find(
            (item: INotification) => item.read_at === null
          )
        );
      } else {
        if (!isNotificationOpen) {
          setNewNotification(true);
        }
      }
    }
  }, [socketNotification]);

  const readAll = () => {
    MarkAllAsRead()
      .then(() => {
        setNewNotification(false);
      })
      .catch(() => {});
  };

  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'end'}
      paddingRight={isMobile ? '0px' : '24px'}
    >
      <Box
        className={classes.depositContainer}
        onClick={() => setIsOpenDepositModal(true)}
      >
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <AirbetDepositIcon />
          <span className={classes.depositAmount}>
            {Number(balance).toFixed(2)}
          </span>
        </Box>
        <DepositButton>
          <AddIcon className={classes.iconSmall} />
          {!isMobile && t('header.deposit')}
        </DepositButton>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={isMobile ? '8px' : '12px'}
        margin={isMobile ? '0px' : '0px 24px'}
        marginLeft={isMobile ? '8px' : '0px'}
      >
        <BonusPopover />
        <HeaderButtonContainer onClick={() => setOpenGlobalSearch(true)}>
          <SearchIcon size={isMobile ? 16 : 20} />
        </HeaderButtonContainer>

        {isMobile && (
          <HeaderButtonContainer
            onClick={() => {
              setIsChatOpen(!isChatOpen);
              setIsNotificationOpen(false);
            }}
          >
            <ChatIcon fill={'#fff'} size={16} />
          </HeaderButtonContainer>
        )}

        <Box position={'relative'}>
          <HeaderButtonContainer
            onClick={() => {
              setIsNotificationOpen(!isNotificationOpen);
              setIsChatOpen(false);
              readAll();
            }}
          >
            <NotificationIcon size={isMobile ? 16 : 20} />
          </HeaderButtonContainer>
          {newNotification && <Box className={classes.newNotification} />}
        </Box>
        {!isMobile && (
          <HeaderButtonContainer
            onClick={() => {
              setIsChatOpen(!isChatOpen);
              setIsNotificationOpen(false);
            }}
          >
            <ChatIcon fill={'#fff'} />
          </HeaderButtonContainer>
        )}
      </Box>

      {!isMobile && (
        <div className={classes.userAccountBtn}>
          <AppHeaderAccountInfoMenu />
        </div>
      )}

      <GlobalSearchContainer
        open={openGlobalSearch}
        setOpen={setOpenGlobalSearch}
      />
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = (dispatch: any) => ({
  updateBalance: bindActionCreators(changeBalance, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchToProps
)(AppHeaderAuthorizedContent);
