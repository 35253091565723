import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Box, Grid } from '@mui/material';
import DepositIcon from '../../api/ui/svgIcons/DepositIcon';
import CustomSelect from '../common/Select/CustomSelect';
import { ICurrency } from '../../types/common.type';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { isMobile } from 'react-device-detect';
import CustomInput from '../common/Inputs/CustomInput';
import { ChangeEvent, useState } from 'react';
import VisaIcon from '../../api/ui/svgIcons/CryptoIcons/VisaIcon';
import MasterCardIcon from '../../api/ui/svgIcons/CryptoIcons/MasterCardIcon';
import ApplePayIcon from '../../api/ui/svgIcons/CryptoIcons/ApplePayIcon';
import GooglePayIcon from '../../api/ui/svgIcons/CryptoIcons/GooglePayIcon';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { depositFiat } from '../../api/services/Interkasa';

const useStyles = makeStyles((theme: any) => ({
  root: {
    ...theme.general.accountBody,
  },
  rootMobile: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#111827',
    overflowY: 'auto',
    height: `${window.innerHeight - 150}px`,
  },
  header: {
    margin: '16px 0px',
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& path': {
      fill: '#fff',
    },
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  itemName: {
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  qrContainer: {
    display: 'flex',
    background: '#0D121D',
    padding: '16px',
    gap: '16px',
    boxSizing: 'border-box',
    borderRadius: '8px',
    margin: isMobile ? '8px' : '8px 16px 16px 16px',
  },
  depositAddress: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  memoAddress: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  depositAddressDescription: {
    color: '#6B7280',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    marginTop: 'auto',
  },
  address: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    lineBreak: 'anywhere',
  },
  hasTag: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    lineBreak: 'anywhere',
  },
  selectCurrencyAndNetwork: {
    color: '#9CA3AF',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '24px',
  },
  minimumAmountContainer: {
    margin: isMobile ? '8px' : '8px 16px 8px 16px',
    background: '#FFFFFF0D',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  minimumAmountContainerText: {
    color: '#FFFFFF80',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  feeText: {
    color: '#FFFFFF80',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    marginLeft: isMobile ? '8px' : '23px',
    marginBottom: '16px',
  },
  minimumAmountContainerAmount: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
  },
  minimumAmountContainerAmountText: {
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  hideHeader: {
    border: 'none',
  },
  headerLine: {
    width: '100%',
    border: '1px solid #FFFFFF0D',
    marginTop: !isMobile ? '20px' : '5px',
  },
  inputContainer: {
    marginTop: '4px',
    display: 'flex',
    width: '100%',
  },
  paymentMethodTextStyle: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#9CA3AF',
    textTransform: 'uppercase',
    marginBottom: '24px',
    marginTop: '16px',
    marginLeft: '16px',
  },
  moneyComponentContainer: {
    display: 'flex',
    gap: '16px',
    marginTop: '24px',
    marginBottom: '16px',

    '& .price_container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexBasis: '25%',
      gap: '4px',
      height: '32px',
      background: '#FFFFFF0D',
      borderRadius: '4px',
      paddingTop: '8px',
      paddingBottom: '8px',
      boxSizing: 'border-box',
      cursor: 'pointer',

      '& h5': {
        color: 'rgba(255, 255, 255, 0.92)',
        fontWeight: 700,
        fontSize: '16px',
      },
    },

    '& .payment_container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexBasis: '25%',
      gap: '4px',
      height: '48px',
      background: '#FFFFFF0D',
      borderRadius: '4px',
      paddingTop: '14px',
      paddingBottom: '14px',
      boxSizing: 'border-box',
      cursor: 'pointer',
      transition: 'border 0.3s ease-in-out',
    },
  },
}));

type PaymentMethod = 'mastercard' | 'visa' | 'gpaym' | 'apaym';

interface IDeposit {
  currencies: ICurrency[];
  selectedCurrency: ICurrency | null;
  address: string | null;
  hasTag: string | null;
  setHasTag: (hasTag: string | null) => void;
  setAddress: any;
  hideHeader?: boolean;
  amount: number | null;
  setAmount: (amount: number) => void;
}

const DepositFiat = ({
  amount,
  setAmount,
  hideHeader,
  currencies,
  selectedCurrency,
}: IDeposit) => {
  const classes = useStyles({ hideHeader });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedPayment, setSelectedPayment] =
    useState<PaymentMethod>('mastercard');
  2;
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = yup.object({
    amount: yup
      .number()
      .required(t('fillAllFields'))
      .positive(t('moreThanZero')),
    selectedPayment: yup.string().required(t('fillAllFields')),
  });

  const currencyRendererData = () => {
    return currencies.map((item: ICurrency) => {
      return {
        ...item,
        value: item.name,
        renderer: () => (
          <Box
            display={'flex'}
            gap={'8px'}
            alignItems={'center'}
            height={'24px'}
          >
            {item.logo && (
              <img
                src={getBaseUrlStatic() + item.logo}
                alt={item.name}
                width={'24px'}
                height={'24px'}
              />
            )}
            <span className={classes.itemName}>{item.name}</span>
          </Box>
        ),
      };
    });
  };

  const onFillAmount = (value: number) => setAmount(value);
  const onSelectPayment = (value: PaymentMethod) => setSelectedPayment(value);

  const renderMonayComponent = () => (
    <div className={classes.moneyComponentContainer}>
      <div className="price_container" onClick={() => onFillAmount(50)}>
        <h5>$50.00</h5>
      </div>
      <div className="price_container" onClick={() => onFillAmount(100)}>
        <h5>$100.00</h5>
      </div>
      <div className="price_container" onClick={() => onFillAmount(250)}>
        <h5>$250.00</h5>
      </div>
      <div className="price_container" onClick={() => onFillAmount(500)}>
        <h5>$500.00</h5>
      </div>
    </div>
  );

  const renderPaymentMethodComponent = (selectedPayment: PaymentMethod) => (
    <div className={classes.moneyComponentContainer}>
      <div
        className="payment_container"
        style={{
          border:
            selectedPayment === 'mastercard' ? '1px solid #2563EB' : 'initial',
        }}
        onClick={() => onSelectPayment('mastercard')}
      >
        <MasterCardIcon fill="#fff" />
      </div>

      <div
        className="payment_container"
        style={{
          border: selectedPayment === 'visa' ? '1px solid #2563EB' : 'initial',
        }}
        onClick={() => onSelectPayment('visa')}
      >
        <VisaIcon fill="#fff" />
      </div>

      <div
        className="payment_container"
        style={{
          border: selectedPayment === 'apaym' ? '1px solid #2563EB' : 'initial',
        }}
        onClick={() => onSelectPayment('apaym')}
      >
        <ApplePayIcon fill="#fff" />
      </div>

      <div
        className="payment_container"
        style={{
          border: selectedPayment === 'gpaym' ? '1px solid #2563EB' : 'initial',
        }}
        onClick={() => onSelectPayment('gpaym')}
      >
        <GooglePayIcon />
      </div>
    </div>
  );

  const validateForm = async (values: {
    amount: number | null;
    selectedPayment: string;
  }): Promise<boolean> => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      return true;
    } catch (err: any) {
      const validationErrors: { amount?: string; selectedPayment?: string } =
        {};
      err.inner.forEach((error: yup.ValidationError) => {
        validationErrors[error.path as keyof typeof validationErrors] =
          error.message;
      });

      enqueueSnackbar(validationErrors.amount, {
        variant: 'error',
      });
      return false;
    }
  };

  const onSubmit = async () => {
    const isValid = await validateForm({
      amount: Number(amount),
      selectedPayment,
    });

    if (isValid) {
      setLoading(true);
      try {
        const response = await depositFiat({
          ik_payment_method: selectedPayment,
          ik_am: amount,
        });

        if (response.data.success) {
          setLoading(false);
          window.open(response.data.data.url, '_blank');
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      className={
        !hideHeader
          ? isMobile
            ? classes.rootMobile
            : classes.root
          : classes.hideHeader
      }
    >
      {!isMobile && !hideHeader && (
        <div className={classes.header}>
          <DepositIcon size={20} />
          <span>{t('interkasa.depositFiat')}</span>
        </div>
      )}
      {!isMobile && !hideHeader && (
        <Box
          style={{
            background: '#FFFFFF0D',
            width: '100%',
            height: '1px',
          }}
        />
      )}

      <Box>
        <Box padding={isMobile ? '16px 8px 0px 8px' : '16px 16px 0px 16px'}>
          <Grid container spacing={3}>
            <Grid item xs={8} sm={9}>
              <CustomInput
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAmount(Number(event.target.value))
                }
                label={t('amount')}
                type={'number'}
                value={amount ? amount : ''}
                placeholder={'0.00'}
              />
            </Grid>

            <Grid item xs={4} sm={3}>
              {currencies && (
                <CustomSelect
                  onChange={(e) => console.log(e)}
                  value={selectedCurrency?.name || ''}
                  label={t('currency')}
                  data={currencyRendererData()}
                />
              )}
            </Grid>
          </Grid>

          {renderMonayComponent()}
        </Box>

        <Box
          style={{
            background: 'rgba(255, 255, 255, 0.05)',
            width: '100%',
            height: '1px',
          }}
        />

        <Box padding={isMobile ? '0px 8px 16px 8px' : '0px 16px'}>
          <h6 className={classes.paymentMethodTextStyle}>
            {t('paymentMethod')}
          </h6>
          {renderPaymentMethodComponent(selectedPayment)}
        </Box>

        <Box
          style={{
            background: 'rgba(255, 255, 255, 0.05)',
            width: '100%',
            height: '1px',
          }}
        />

        <Box
          padding={isMobile ? '16px 8px' : '16px'}
          justifyContent={'start'}
          width={'100%'}
          display={'flex'}
          boxSizing={'border-box'}
          style={{
            boxShadow: isMobile ? 'rgb(19 19 19 / 55%) 0px -7px 9px 0px' : '',
          }}
        >
          <CustomPrimaryButton
            style={{
              textTransform: 'uppercase',
              width: isMobile ? '100%' : '',
            }}
            disabled={loading}
            loading={loading}
            onClick={onSubmit}
            fullWidth={isMobile ? '100%' : 'auto'}
          >
            {t('proceedtoPayment')}
          </CustomPrimaryButton>
        </Box>
      </Box>
    </div>
  );
};

export default DepositFiat;
