import { Box, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChangeEvent, useState } from 'react';
import EyeIcon from '../../../api/ui/svgIcons/EyeIcon';

interface ICustomInput {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  isRequired?: boolean;
  type?: string;
  value?: any;
  startAdornment?: any;
  endAdornment?: any;
  secondary?: boolean;
  size?: string;
  disabled?: boolean;
  onKeyPress?: () => void;
  isGap?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
  style?: any;
  error?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0,
      },
  },
  input: {
    background: '#0D121D',
    borderRadius: '8px',
    width: '100%',
    '& input': {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0px',
      textAlign: 'left',
      padding: '12px 16px',
      height: '24px',
      '&::placeholder': {
        color: '#4B5563',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': { borderColor: 'transparent' },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '27px',
    letterSpacing: '0px',
    textAlign: 'left',
    paddingLeft: '16px',
    paddingBottom: '4px',
    color: '#9CA3AF',
    textTransform: 'uppercase',
  },
  required: {
    color: '#B8291D',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    marginLeft: '4px',
  },
  secondaryInput: {
    background: '#030712',
    borderRadius: '12px',
    border: '1px solid #1F2937',
    '& input': {
      height: '22px',
    },
  },
  withAdornment: {
    '& input': {
      padding: '12px 16px 12px 0px',
    },
  },
  smallSize: {
    '& input': {
      padding: '8px 12px 8px 0px',
    },
  },
  disabledInput: {
    '& input': {
      color: '#fff !important',
      '-webkit-text-fill-color': '#fff !important',
    },
  },
  inputContainer: {
    marginTop: '8px',
  },
  inputErrorParent: {
    display: 'block',
    borderRadius: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: 'rgba(69, 14, 10, 0.5)',
  },
  inputErrorText: {
    fontSize: '12px',
    lineHeight: '24px',
    color: 'rgba(202, 21, 21, 1)',
  },
  eyeIconStyle: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: '16px',
    cursor: 'pointer',
    height: '20px',
    zIndex: 2,
  },
}));

const CustomInput = ({
  onChange,
  defaultValue,
  placeholder,
  label,
  isRequired,
  type,
  value,
  startAdornment,
  endAdornment = false,
  secondary = false,
  size,
  disabled = false,
  onKeyPress,
  isGap,
  onBlur,
  autoFocus = false,
  style = {},
  error,
}: ICustomInput) => {
  const [inputType, setInputType] = useState(type);
  const classes = useStyles();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onKeyPress) {
      event.preventDefault();
      onKeyPress();
    }
  };

  const showPassword = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <Box className={classes.root}>
      {label && <span className={classes.label}>{label}</span>}
      {isRequired && <span className={classes.required}>*</span>}
      <form style={{ marginTop: isGap ? '5px' : '', position: 'relative' }}>
        <TextField
          variant="outlined"
          className={`${classes.input} ${secondary && classes.secondaryInput} ${startAdornment && classes.withAdornment} ${size === 'small' && classes.smallSize}  ${disabled && classes.disabledInput}`}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          defaultValue={defaultValue}
          onChange={onChange}
          type={inputType}
          autoComplete="off"
          value={value}
          InputProps={{
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          disabled={disabled}
          onBlur={() => onBlur && onBlur()}
          autoFocus={autoFocus}
          sx={{
            ...style,
          }}
        />

        {type === 'password' && (
          <div className={classes.eyeIconStyle} onClick={showPassword}>
            <EyeIcon size={16} type={inputType} />
          </div>
        )}
      </form>

      {error && (
        <div className={classes.inputContainer}>
          <InputError error={error} />
        </div>
      )}
    </Box>
  );
};

export default CustomInput;

const InputError = ({ error }: { error: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.inputErrorParent}>
      <p className={classes.inputErrorText}>{error}</p>
    </div>
  );
};
