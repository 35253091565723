import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';
import { Box } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../../util/appRoutePath';
import { isMobile } from 'react-device-detect';

interface IWalletMobileWrapper {
  children: ReactNode;
  title: string;
}

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    background: '#0D121D',
  },
  header: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '16px 8px',
    alignItems: 'center',
    gap: '10px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    background: '#0D121D',
    width: '100%',
    position: isMobile ? 'fixed' : 'static',

    '& span': {
      color: '#FFF',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    '& .MuiSvgIcon-root': {
      color: '#6B7280',
    },
  },
}));

const WalletMobileWrapper = ({ children, title }: IWalletMobileWrapper) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <KeyboardArrowLeftIcon
          onClick={() => navigate(AppRoutePath.WALLET_MOBILE_PAGE())}
        />
        <span>{title}</span>
      </Box>
      <Box
        height={isMobile ? '100%' : 'auto'}
        style={{
          background: '#0D121D',
          paddingTop: '50px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default WalletMobileWrapper;
