import { IGamePage, IGameSchema } from '../../types/Game.type';
import { makeStyles } from '@mui/styles';
import Container from '../common/BoxContainer/Container';
import { useTranslation } from 'react-i18next';
import CustomInput from '../common/Inputs/CustomInput';
import { Box } from '@mui/material';
import CustomShowOrHideSelect from './common/CustomShowOrHideSelect';
import CustomSortBySelect from './common/CustomSortBySelect';
import CustomProviderSelect from './common/CustomProviderSelect';
import SearchIcon from '../../api/ui/svgIcons/SearchIcon';
import { ChangeEvent, useEffect } from 'react';
import GameCard from '../GameCard/GameCard';
import { isMobile } from 'react-device-detect';
import LoadMoreGames from './common/LoadMoreGames';
import NoGameResult from './common/NoGameResult';
import GameCardSkeleton from '../GameCard/GameCardSkeleton';
import { useNavigate } from 'react-router-dom';
import CasinoSwitcher from '../Casino/CasinoSwitcher';
import CasinoMobileSwitcher from '../Casino/CasinoMobileSwitcher';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  header: {
    ...theme.general.pageHeader,
    padding: isMobile ? '0px 16px' : '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
  },
  body: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: isMobile ? '25px' : '40px',
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    alignItems: 'center',
    gap: '32px',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      gap: '16px',
    },

    '& .search-icon': {
      fill: '#6B7280',
    },
  },
  screenDescStyle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#fff',
  },
}));

const GamePage = ({
  selectedBlockGame,
  onChangeBlockedGames,
  selectedSortBy,
  onChangeSort,
  selectedProviders,
  onChangeProviders,
  onChangeSearchValue,
  currentSearchValue,
  games,
  loading,
  totalResult,
  loadMoreGames,
  loadMoreLoading,
  firstLoading,
  onClearParams,
  pageName,
  hideGameProviderFilter,
  hideGameSortByFilter,
  hideBlockedGameFilter,
  hideFooter,
  favourite,
  addToFavoriteGameList,
  removeFromFavoriteGameList,
  categorySlug,
  showSwitcher = false,
  isHighRTPScreen = false,
}: IGamePage) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Box
          maxWidth={'1160px'}
          width={'100%'}
          margin={'0px auto'}
          boxSizing={'border-box'}
        >
          <span className={classes.headerText}>{t(pageName)}</span>
        </Box>
      </div>

      {isHighRTPScreen && (
        <Box
          maxWidth={'1200px'}
          width={'100%'}
          margin={'0px auto'}
          boxSizing={'border-box'}
          padding={isMobile ? '16px 20px 0px 20px' : '20px'}
        >
          <span className={classes.screenDescStyle}>
            {t('airbetMenu.highRTPSCREENTEXT')}
          </span>
        </Box>
      )}
      <Container>
        {showSwitcher && !isMobile && <CasinoSwitcher pageName={pageName} />}
        {isMobile && showSwitcher && (
          <CasinoMobileSwitcher selectedPage={pageName} />
        )}
        <div
          className={classes.body}
          style={{
            padding: isMobile ? '16px' : `24px 20px 0px 20px`,
          }}
        >
          <div className={classes.filterContainer}>
            <CustomInput
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onChangeSearchValue(event.target.value)
              }
              secondary={true}
              startAdornment={<SearchIcon pathClassName={'search-icon'} />}
              value={currentSearchValue}
            />
            {(!hideGameProviderFilter ||
              !hideGameSortByFilter ||
              !hideGameSortByFilter) && (
              <Box display={'flex'} gap={'18px'} width={'100%'}>
                {!isMobile && !hideBlockedGameFilter && (
                  <CustomShowOrHideSelect
                    selectedBlockGame={selectedBlockGame}
                    onChangeBlockedGames={onChangeBlockedGames}
                  />
                )}
                {!hideGameProviderFilter && (
                  <CustomProviderSelect
                    onChangeProvider={onChangeProviders}
                    selectedProviders={selectedProviders}
                  />
                )}
                {!hideGameSortByFilter && (
                  <CustomSortBySelect
                    selectedSortByValue={selectedSortBy}
                    onChangeSortBy={onChangeSort}
                  />
                )}
              </Box>
            )}
          </div>

          {totalResult === 0 && !firstLoading ? (
            <NoGameResult onClearFilter={onClearParams} />
          ) : (
            <Box>
              <Box
                style={{
                  opacity: !firstLoading && loading ? 0.3 : 1,
                  transition: 'opacity 0.3s ease-int-out',
                }}
              >
                {firstLoading ? (
                  <Box
                    style={{
                      display: 'flex',
                      gap: isMobile ? '8px' : '16px',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    {Array.from({
                      length: 28,
                    }).map((_, index) => (
                      <GameCardSkeleton key={index} />
                    ))}
                    {Array.from({
                      length: 7,
                    }).map((_, index) => (
                      <Box
                        key={`${index}_empty_card`}
                        width={isMobile ? '104px' : '150px'}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      gap: isMobile ? '8px' : '16px',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    {games.map((game: IGameSchema, index: number) => (
                      <Box key={index}>
                        <GameCard
                          gameName={game.game_title}
                          gameProvider={game.provider}
                          backgroundImageUrl={game.image || ''}
                          isFavorite={
                            !!favourite.find(
                              (item: IGameSchema) => item.id === game.id
                            )
                          }
                          isAvailable={!game.coming_soon && !game.is_restricted}
                          comingSoon={!!game.coming_soon}
                          addToFavorites={() => {
                            addToFavoriteGameList(String(game.id));
                          }}
                          removeFromFavorite={() =>
                            removeFromFavoriteGameList(String(game.id))
                          }
                          startPlaying={() => navigate(`/games/${game.slug}`)}
                          isMobile={isMobile}
                          agregator_image={game.agregator_image}
                          gameId={game.game_id}
                          categorySlug={categorySlug ? categorySlug : 'global'}
                          index={index}
                        />
                      </Box>
                    ))}
                    {Array.from({
                      length: 7,
                    }).map((_, index) => (
                      <Box
                        key={`${index}_empty_card`}
                        width={isMobile ? '104px' : '150px'}
                      />
                    ))}
                  </Box>
                )}
              </Box>
              {!hideFooter && !firstLoading && (
                <LoadMoreGames
                  currentGamesCount={games.length}
                  totalGamesCount={totalResult}
                  loadMoreGames={loadMoreGames}
                  loadMoreLoading={loadMoreLoading}
                />
              )}
            </Box>
          )}
        </div>
      </Container>
    </div>
  );
};

export default GamePage;
