import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RankSystemBackgroundIcon from '../../api/ui/svgIcons/RankSystemBackgroundIcon';

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100%' : '320px',
    borderRadius: '16px',
    border: '1px solid #FFFFFF0D',
  },
  wrapper: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  headLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#FFF',
    gap: '8px',
    marginBottom: '24px',
  },
  RankItemRoot: {
    width: '100%',
    borderRadius: '8px',
    borderTop: '1px solid #FFFFFF0D',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#FFF',
    padding: '12px 16px',
    background: '#FFFFFF06',
    boxSizing: 'border-box',
    '& :first-child': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#BBC3DB',
    },
  },
  numericAlamn: {
    borderRadius: '12px',
    borderTop: '1px solid #FFFFFF26',
    padding: '1.5px 8px 3.5px 8px',
    gap: '24px',
    rotate: '-0',
    position: 'absolute',
    boxShadow: '0px 1px 2px 0px #000000BF',
    background: 'linear-gradient(180deg, #263246 0%, #1E2A3D 100%)',
    top: '16px',
    left: '16px',
    '& span': {
      color: '#FFF',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '27px',
    },
  },
}));

interface RankSystemCardProps {
  rankInfo: any;
  fullWidth?: boolean;
  index: number;
  currentPage: number;
}

const RankSystemCard: FC<RankSystemCardProps> = ({ rankInfo, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const rankItemsInfoObj = [
    {
      name: t('wagerRequired'),
      amount: rankInfo.min_wager,
    },
    // {
    //   name: t('rankBonus'),
    //   amount: undefined,
    // },
    // {
    //   name: t('levelUpBonus'),
    //   amount: undefined,
    // },
    {
      name: t('cashback'),
      percent: rankInfo.cashback_percent,
    },
    {
      name: t('rakeback'),
      percent: rankInfo.rakeback_percent,
    },
  ];

  const RankItemInfo = (
    info: {
      name: string;
      amount?: number | undefined;
      percent?: number | undefined;
    },
    index: number
  ) => {
    return (
      <Box className={classes.RankItemRoot} key={index.toString()}>
        <span>{t(info.name)}</span>
        <span>
          {info.amount !== undefined
            ? `${t(info.amount.toString())}$`
            : `${t(info.percent?.toString() ?? '0')}%`}
        </span>
      </Box>
    );
  };

  return (
    <Box
      style={{
        background: rankInfo.background,
        borderRadius: '16px',
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <Box className={classes.imgContainer}>
            <Box
              style={{
                position: 'absolute',
                top: '0',
                width: '687px',
                height: '627px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <RankSystemBackgroundIcon />
            </Box>
            <Box zIndex={2}>
              <img
                alt={'rank img'}
                src={'/imgs/Rank/' + rankInfo.img}
                width={'128px'}
                height={'128px'}
                style={{ marginTop: '16px' }}
              />
              <Box className={classes.headLine}>
                <span>{rankInfo.name}</span>
                <span>
                  {rankInfo.min_level}-{rankInfo.max_level}
                </span>
              </Box>
            </Box>
            <Box className={classes.numericAlamn}>
              <span>{index < 9 ? `0${index + 1}` : index + 1}</span>
            </Box>
          </Box>
          <Box
            style={{
              width: '100%',
              padding: '0px 8px 8px 8px',
              boxSizing: 'border-box',
              gap: '8px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {rankItemsInfoObj.map((item, index) => {
              return RankItemInfo(item, index);
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RankSystemCard;
