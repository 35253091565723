import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { Box, Skeleton } from '@mui/material';
import CustomModal from '../common/Modal/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import { ILeaderBoardInfo } from '../../types/LeaderBoard.type';
import { getBaseUrlStatic } from '../../api/baseUrl';

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100%' : 'auto',
    borderRadius: '12px',
    display: 'flex',
    boxSizing: 'border-box',
    background: '#111827',
    flexDirection: 'column',
    height: isMobile ? `${window.innerHeight - 61}px` : '',
  },
  header: {
    padding: '16px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-between',
    background: isMobile ? '#0D121D' : '',
    '& path': {
      fill: '#fff',
    },
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  headerLine: {
    width: '100%',
    border: '1px solid #FFFFFF0D',
  },
  mobileModal: {
    position: 'fixed',
    width: '100%',
    zIndex: 99,
    top: 0,
  },
  body: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    gap: '16px',
    paddingBottom: isMobile ? '100px' : '',
  },
  post: {
    color: '#9CA3AF',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    maxWidth: '640px',
    maxHeight: !isMobile ? '400px' : '100%',
  },
  imageSkeleton: {
    width: isMobile ? '100%' : '640px',
    height: isMobile ? '232px' : '354px',
    maxHeight: '354px',
    maxWidth: '640px',
    borderRadius: '8px',
    background: 'rgb(43 55 80 / 42%)',
  },
}));

interface leaderBoardInfoProps {
  isOpenInfoModal: boolean;
  setIsOpenInfoModal: (isOpen: boolean) => void;
  data: ILeaderBoardInfo | undefined;
  image: string | null | undefined;
}

const LeaderBoardInfoModal: FC<leaderBoardInfoProps> = ({
  isOpenInfoModal,
  setIsOpenInfoModal,
  data,
  image,
}) => {
  const classes = useStyles();

  const getBody = () => {
    return (
      <Box className={classes.root}>
        <Box className={classes.header}>
          <span>{data?.name}</span>
          {isMobile && (
            <CloseIcon
              style={{
                color: 'rgba(255, 255, 255, 0.5)',
                cursor: 'pointer',
              }}
              onClick={() => setIsOpenInfoModal(false)}
            />
          )}
        </Box>
        <Box className={classes.headerLine} />
        <Box className={classes.body}>
          {!image ? (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Skeleton variant="rounded" className={classes.imageSkeleton} />
            </Box>
          ) : (
            <img
              src={getBaseUrlStatic() + '/' + image}
              alt={'info image'}
              style={{
                maxWidth: '640px',
                maxHeight: '354px',
                borderRadius: '8px',
              }}
            />
          )}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data?.post ?? '',
              }}
              className={classes.post}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return isMobile ? (
    <Box className={classes.mobileModal}>{getBody()}</Box>
  ) : (
    <CustomModal
      open={isOpenInfoModal}
      onClose={() => setIsOpenInfoModal(false)}
      fullScreen={isMobile ? '100%' : ''}
    >
      {getBody()}
    </CustomModal>
  );
};

export default LeaderBoardInfoModal;
