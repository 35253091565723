const HightRtp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={14}
    fill="none"
    style={{ paddingLeft: '1px' }}
  >
    <path
      fill="#fff"
      d="M7 .438a6.563 6.563 0 1 0 0 13.125A6.563 6.563 0 0 0 7 .437Zm1.125 6.191v4.09h-2.25v-4.09H3.5L7 3.281l3.5 3.348H8.125Z"
    />
  </svg>
);
export default HightRtp;
