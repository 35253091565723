const NewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={14}
    fill="none"
    className="new-icon"
  >
    <rect width={32} height={14} fill="#E6374B" rx={3} />
    <path
      fill="#fff !important"
      d="M6.157 10H5V4h1.062l3.38 4.308h.054V4h1.157v6H9.596L6.205 5.68h-.048V10ZM17.09 9.16V10H12.57V4h4.522v.84h-3.323v1.71h3.126v.804h-3.126V9.16h3.323ZM21.05 10h-1.093l-1.953-6h1.337l1.205 4.567h.058L21.958 4h1.093L24.4 8.567h.064L25.663 4H27l-1.953 6h-1.094l-1.427-4.444h-.048L21.05 10Z"
    />
  </svg>
);
export default NewIcon;
