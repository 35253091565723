import HighRTPGameContainer from './HighRTPGameContainer';

const HighRTPContainer = () => {
  return (
    <HighRTPGameContainer
      categorySlugName={'slots'}
      pageName={'airbetMenu.highRTPSCREENDESC'}
      showSwitcher={true}
      hideGameProviderFilter
    />
  );
};

export default HighRTPContainer;
