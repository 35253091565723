import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import CustomInput from '../common/Inputs/CustomInput';
import { getBaseUrlStatic } from '../../api/baseUrl';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { isMobile } from 'react-device-detect';

interface IUserProfile {
  avatar: string | null;
  username: string;
  setStage: (stage: number) => void;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '12px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.50)',
    width: isMobile ? `${window.innerWidth}px` : '426px',
  },
  headerText: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  header: {
    padding: '16px',
    borderBottom: '1px solid #1F2937',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    width: '100%',
    '& .MuiSvgIcon-root': {
      color: '#6B7280',
      width: '20px',
      height: '20px',
    },
  },
  body: {
    borderBottom: '1px solid #1F2937',
    background: '#0D121D',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: '184px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  img: {
    width: '96px',
    height: '96px',
  },
  goBack: {
    '& .MuiSvgIcon-root': {
      color: '#6B7280',
      width: '16px',
      height: '16px',
    },
    cursor: 'pointer',
  },
}));

const UserProfile = ({ avatar, username, setStage, onClose }: IUserProfile) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            className={classes.goBack}
            onClick={() => setStage(0)}
          >
            <ArrowBackIosIcon />
          </Box>
          <span className={classes.headerText}>{t('myProfile')}</span>
        </Box>
        <Box
          onClick={() => onClose()}
          display={'flex'}
          alignItems={'center'}
          style={{
            cursor: 'pointer',
          }}
          sx={{
            '& .MuiSvgIcon-root': {
              color: '#6B7280',
            },
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Box className={classes.body}>
        <Box className={classes.img}>
          {avatar ? (
            <img
              src={getBaseUrlStatic() + avatar}
              alt={'user_profile_img'}
              width={'96px'}
              height={'96px'}
              style={{
                borderRadius: '100%',
              }}
            />
          ) : (
            <img
              src={`/imgs/DefaultAvatars/Avatar.png`}
              alt={'user_profile_img'}
              width={'96px'}
              height={'96px'}
              style={{
                borderRadius: '100%',
              }}
            />
          )}
        </Box>
        <CustomMinimalButton
          style={{
            textTransform: 'uppercase',
          }}
          onClick={() => setStage(2)}
        >
          {t('editAvatar')}
        </CustomMinimalButton>
      </Box>
      <Box padding={'16px'}>
        <CustomInput
          onChange={() => {}}
          value={username}
          label={t('username')}
          disabled={true}
        />
      </Box>
    </Box>
  );
};

export default UserProfile;
