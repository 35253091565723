import React, { ChangeEvent, FC } from 'react';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import CustomModal from '../common/Modal/CustomModal';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomInput from '../common/Inputs/CustomInput';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { useTranslation } from 'react-i18next';
import { IPassword } from '../../containers/ResetPassword.tsx/ResetPasswordContainer';

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100%' : '416px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    border: '1px solid rgb(31, 41, 55)',
    background: 'rgb(17, 24, 39)',
    boxShadow: 'rgb(0 0 0 / 50%)',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    boxSizing: 'border-box',
    '& span': {
      lineHeight: '24px',
      fontWeight: 600,
      fontSize: '16px',
      color: '#FFF',
    },
  },
  emailPart: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
    boxSizing: 'border-box',
    borderTop: '1px solid rgb(32 41 58)',
    borderBottom: '1px solid rgb(32 41 58)',
  },
}));

interface ResetPasswordPageProps {
  password: IPassword;
  setPassword: (body: IPassword) => void;
  onSubmit: () => void;
}

const ResetPasswordPage: FC<ResetPasswordPageProps> = ({
  password,
  setPassword,
  onSubmit,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onChangeUpdateInfo = (key: string, value: string) => {
    setPassword({ ...password, [key]: value });
  };

  return (
    <div>
      <CustomModal
        open={true}
        onClose={() => navigate(AppRoutePath.HOME())}
        customStyle={{ width: isMobile ? '85%' : '' }}
      >
        <Box className={classes.root}>
          <Box className={classes.header}>
            <span>{t('resetPassword')}</span>
            <CloseIcon
              sx={{ color: 'rgb(88, 100, 124)', cursor: 'pointer' }}
              fontSize={'small'}
              onClick={() => navigate(AppRoutePath.HOME())}
            />
          </Box>

          <Box className={classes.emailPart}>
            <Box style={{ width: '100%', boxSizing: 'border-box' }}>
              <CustomInput
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChangeUpdateInfo('password', event.target.value)
                }
                label={t('password')}
                placeholder={t('password')}
                type={'password'}
                isGap
              />
            </Box>

            <Box style={{ width: '100%', boxSizing: 'border-box' }}>
              <CustomInput
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChangeUpdateInfo(
                    'password_confirmation',
                    event.target.value
                  )
                }
                label={t('repeatPassword')}
                placeholder={t('repeatPassword')}
                type={'password'}
                isGap
              />
            </Box>
          </Box>

          <Box
            width="100%"
            style={{
              marginTop: isMobile ? '64px' : '0px',
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
            <CustomPrimaryButton
              style={{ width: '100%', textTransform: 'uppercase' }}
              onClick={onSubmit}
              disabled={
                !(
                  password.password.trim() ===
                  password.password_confirmation.trim()
                ) ||
                password.password.trim() === '' ||
                password.password_confirmation.trim() === ''
              }
            >
              {t('change')}
            </CustomPrimaryButton>
          </Box>
        </Box>
      </CustomModal>
    </div>
  );
};

export default ResetPasswordPage;
