export const CHANGE_USER_AUTHORIZATION = 'CHANGE_USER_AUTHORIZATION';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const CHANGE_USER_INFOVERIFICATION_STATUS =
  'CHANGE_USER_INFOVERIFICATION_STATUS';
export const CHANGE_ACCESS_TOKEN = 'CHANGE_ACCESS_TOKEN';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_IS_CURRENT_COUNTRY_RESTRICTED =
  'CHANGE_IS_CURRENT_COUNTRY_RESTRICTED';

export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';

export const CHANGE_RANKS_INFO = 'CHANGE_RANKS_INFO';
export const CHANGE_USER_PROFILE_INFORMATION =
  'CHANGE_USER_PROFILE_INFORMATION';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_PHONE = 'CHANGE_PHONE';

export const GLOBAL_USER_LOGIN_MODAL_OPEN = 'GLOBAL_USER_LOGIN_MODAL_OPEN';
export const GLOBAL_USER_REGISTRATION_MODAL_OPEN =
  'GLOBAL_USER_REGISTRATION_MODAL_OPEN';

export const GLOBAL_USER_REGISTRATION_AND_LOGIN_MODAL_CLOSE =
  'GLOBAL_USER_REGISTRATION_AND_LOGIN_MODAL_CLOSE';
export const BONUS_CLAIM_MODAL_STATUS_INFO = 'BONUS_CLAIM_MODAL_STATUS_INFO';

export const UPDATE_BALANCE = 'UPDATE_BALANCE';

export const CHANGE_MAIN_HEADER_VISIBILITY = 'CHANGE_MAIN_HEADER_VISIBILITY';

export const GLOBAL_BONUS_WHEEL_MODAL_CLOSE = 'GLOBAL_BONUS_WHEEL_MODAL_CLOSE';

export const GLOBAL_BONUS_WHEEL_MODAL_OPEN = 'GLOBAL_BONUS_WHEEL_MODAL_OPEN';

export const CHANGE_WHEEL_SPINS_INFO = 'CHANGE_WHEEL_SPINS_INFO';

export const CHANGE_GLOBAL_DEPOSIT_MODAL = 'CHANGE_GLOBAL_DEPOSIT_MODAL';
export const CHANGE_SIDEBAR_OPEN = 'CHANGE_SIDEBAR_OPEN';
