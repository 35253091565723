import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import GoogleAuthIcon from '../../api/ui/svgIcons/GoogleAuthIcon';
import GameSwiper from '../common/GameSwiper/GameSwiper';
import { isMobile } from 'react-device-detect';
import { AppRoutePath } from '../../util/appRoutePath';
import Container from '../common/BoxContainer/Container';
import { IGameSchema } from '../../types/Game.type';
import AirbetSwitcher from './AirbetSwitcher';
import NoCryptoBanner from '../NoCryptoBanner/NoCryptoBanner';
import BetsTableContainer from '../../containers/BetsTable/BetsTableContainer';
import LobbyAirbetInfo from './LobbyAIrbetInfo';
import { SyntheticEvent, useMemo, useState } from 'react';
import LobbyTabItem from './LobbyTabItem';
import ProviderSwiper from '../Provider/ProviderSwiper';
import MetamaskAuthContainer from '../../containers/MetamaskAuth/MetamaskAuthContainer';
import { getAirCrashSlug, getBaseUrl } from '../../api/baseUrl';
import Banner from './Banner';
import { changeGlobalUserLoginModalOpen } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import LastWinsContainer from '../../containers/RecentWins/RecentWins';
import SportSwiper from '../BetBy/SportSwiper';
import AirbetFireIcon from '../../api/ui/svgIcons/AirbetFireIcon';
import StarIcon from '@mui/icons-material/Star';
import LobbyCard from './LobbyCard';

interface ILobby {
  /*  originalGames: IGameSchema[];*/
  trendingGames: IGameSchema[];
  recommendedGames: IGameSchema[];
  /*  liveCasinoGames: IGameSchema[];
    tableGames: IGameSchema[];
    slotGames: IGameSchema[];
    originalGamesLoading: boolean;*/
  trendingGamesLoading: boolean;
  recommendedGamesLoading: boolean;
  /*  slotGameLoading: boolean;
    liveGameLoading: boolean;
    tableGameLoading: boolean;*/
  isUserAuthenticated: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#0D121D',
  },
  bannersContainer: {
    margin: !isMobile ? '32px 0px' : '8px 0px',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: isMobile ? 'column' : 'row',
    gap: isMobile ? '8px' : '24px',
    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column',
    },
  },
  bannerDescriptionContainer: {
    zIndex: '2',
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
    alignItems: 'center',
  },
  getStarted: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    color: '#fff',
    textTransform: 'uppercase',
    marginBottom: '8px',
  },
  bannerDesc: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: '#FFFFFF80',
    marginBottom: '32px',
  },
  externalAuthBtn: {
    cursor: 'pointer',
    background: '#FFFFFF1A',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    transition: 'background 0.3s',
    gap: '16px',
    '&:hover': {
      background: 'rgb(31 41 55)',
    },
    '& span': {
      color: '#fff',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  directlyJoin: {
    margin: '16px 0px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: '#FFFFFF80',
  },
}));

const Lobby = ({
  /*  originalGames,*/
  trendingGames,
  recommendedGames,
  /*  liveCasinoGames,
                                                                                                                                                                                                                                                                                                                                                                                                                                                       tableGames,
                                                                                                                                                                                                                                                                                                                                                                                                                                                       slotGames,
                                                                                                                                                                                                                                                                                                                                                                                                                                                       originalGamesLoading,*/
  trendingGamesLoading,
  recommendedGamesLoading,
  /*  slotGameLoading,
                                                                                                                                                                                                                                                                                                                                                                                                                                                       liveGameLoading,
                                                                                                                                                                                                                                                                                                                                                                                                                                                       tableGameLoading,*/
  isUserAuthenticated,
}: ILobby) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>('lobby');
  const dispatch = useDispatch();

  const openLoginPage = () => {
    dispatch(changeGlobalUserLoginModalOpen(true));
  };

  const memoizedSlots = useMemo(() => {
    return (
      <LobbyTabItem
        games={trendingGames}
        linkName={t('seeAllSlots')}
        linkSrc={AppRoutePath.GAMELIST()}
        slugName={'slots'}
      />
    );
  }, [trendingGames]);

  /*  const memoizedLiveCasino = useMemo(() => {
      return (
        <LobbyTabItem
          games={liveCasinoGames}
          linkName={t('seeAllLiveCasino')}
          linkSrc={AppRoutePath.LIVECASINO()}
        />
      );
    }, [liveCasinoGames]);

    const memoizedTableGames = useMemo(() => {
      return (
        <LobbyTabItem
          games={tableGames}
          linkName={t('seeAllTableGames')}
          linkSrc={AppRoutePath.TABLE()}
        />
      );
    }, [tableGames]);

    const memoizedGameShows = useMemo(() => {
      return (
        <LobbyTabItem
          games={[]}
          linkName={t('seeAllGameShows')}
          linkSrc={AppRoutePath.GAME_SHOWS()}
        />
      );
    }, []);

    const memoizedOriginalGames = useMemo(() => {
      return (
        <LobbyTabItem
          games={originalGames}
          linkName={t('seeAllGameShows')}
          linkSrc={AppRoutePath.ORIGINAL()}
        />
      );
    }, [originalGames]);*/

  const onGoogleAuth = (event: SyntheticEvent): void => {
    event.preventDefault();
    window.location.replace(getBaseUrl() + '/auth/social/google');
  };

  return (
    <Box width={'100%'} className={classes.root}>
      {!isUserAuthenticated ? (
        <Box margin={isMobile ? 0 : '24px 24px 0px 24px'}>
          <Container>
            <Box
              height={'424px'}
              overflow={'hidden'}
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
              justifyContent={isMobile ? 'center' : 'left'}
              borderRadius={'16px'}
            >
              <Box
                className={classes.bannerDescriptionContainer}
                marginLeft={isMobile ? '0px' : '10%'}
              >
                <span className={classes.getStarted}>{t('getStarted')}</span>
                <span className={classes.bannerDesc}>
                  {t('experienceTruInnovation')}
                </span>
                <CustomPrimaryButton
                  style={{
                    textTransform: 'uppercase',
                  }}
                  onClick={() => openLoginPage()}
                >
                  {t('joinNowAndPlay')}
                </CustomPrimaryButton>
                <span className={classes.directlyJoin}>
                  {t('directlyJoin')}
                </span>
                <Box display={'flex'} gap={'8px'} width={'100%'}>
                  <span
                    className={classes.externalAuthBtn}
                    onClick={(event: SyntheticEvent) => onGoogleAuth(event)}
                  >
                    <GoogleAuthIcon /> <span>{t('google')}</span>
                  </span>
                  <MetamaskAuthContainer
                    className={classes.externalAuthBtn}
                    onClose={() => {}}
                  />
                </Box>
              </Box>
              <img
                src={'/imgs/home_page_banner.png'}
                alt={'home_page_banner_Unauthorized'}
                style={{
                  objectFit: 'cover',
                  position: 'absolute',
                  width: '100%',
                  height: '424px',
                  objectPosition: 'left',
                }}
              />
            </Box>
          </Container>
        </Box>
      ) : (
        <Banner isChatAndSideOpen={false} />
      )}

      {!isMobile && false && (
        <AirbetSwitcher
          onChange={(selectedTab: string) => setActiveTab(selectedTab)}
        />
      )}
      {activeTab === 'slots' && memoizedSlots}
      {/*      {activeTab === 'liveCasino' && memoizedLiveCasino}
      {activeTab === 'tableGames' && memoizedTableGames}
      {activeTab === 'gameShows' && memoizedGameShows}
      {activeTab === 'originals' && memoizedOriginalGames}*/}
      <Box
        margin={isMobile ? '0px 8px' : '0px 24px'}
        style={{
          display: activeTab === 'lobby' ? '' : 'none',
        }}
      >
        <Container>
          <Box className={classes.bannersContainer}>
            <LobbyCard
              labelColor="#3B82F6"
              labelBgColor="#1E4FD940"
              title={t('casino')}
              desc={t('casinoBannerText')}
              img="/imgs/roulette.png"
              altText="roulette img"
              label={t('moreThen7KGames')}
              redirectLink={AppRoutePath.CASINO()}
              linearGradient="linear-gradient(287.43deg, #111827 -8.98%, #111827 53.91%, #1E4FD8 129.42%)"
            />

            <LobbyCard
              title={t('header.sport')}
              desc={t('sportsBannerText')}
              img="/imgs/sport.png"
              altText="sport img"
              label={t('moreThen40Sports')}
              redirectLink={AppRoutePath.SPORT()}
              labelColor="#84CC16"
              labelBgColor="#65A30D40"
              linearGradient="linear-gradient(287.43deg, #111827 -8.98%, #111827 53.91%, #4E7D10 129.42%)"
            />
          </Box>
        </Container>

        <Container>
          <Box className={classes.bannersContainer}>
            <LobbyCard
              labelColor="#EDBC1A"
              labelBgColor="#EDBC1A40"
              title={t('airbetCrush')}
              desc={t('crashGameBannerDescription')}
              img="/imgs/airCrashBannerV2.png"
              altText="AIR CRASH img"
              label={t('originals')}
              redirectLink={`/games/${getAirCrashSlug()}`}
              linearGradient="linear-gradient(287.43deg, #111827 -8.98%, #111827 60.22%, #D6B756 129.42%)"
            />

            <LobbyCard
              title={t('airbetMenu.highRTP')}
              desc={t('highRtpBannerDesc')}
              img="/imgs/highRtp.png"
              altText="99% RTP Slots"
              label={t('highRtpBannerText')}
              redirectLink="/high-rtp"
              labelColor="#8074F1"
              labelBgColor="#8074F140"
              linearGradient="linear-gradient(91.47deg, rgb(81, 69, 191) 1.25%, rgb(44, 43, 81) 31.81%, rgb(16 24 39) 94.64%)"
            />
          </Box>
        </Container>
        <Container>
          <div
            style={{
              margin: isMobile ? '0px 8px' : '',
            }}
          >
            {/*          <Box display={'none'}>
            <GameSwiper
              title={t('airbetMenu.airbetOriginals')}
              icon={<AirbetOriginalMenuIcon size={isMobile ? 16 : 20} />}
              seeAllLink={AppRoutePath.ORIGINAL()}
              games={originalGames}
              loading={originalGamesLoading}
            />
          </Box>*/}
            <LastWinsContainer />
            {!isMobile && (
              <NoCryptoBanner isUserAuthenticated={isUserAuthenticated} />
            )}
            <GameSwiper
              title={t('trendingGames')}
              icon={<AirbetFireIcon size={isMobile ? 16 : 20} />}
              seeAllLink={AppRoutePath.GAMELIST()}
              games={trendingGames}
              loading={trendingGamesLoading}
            />
            <GameSwiper
              title={t('recommendedGames')}
              icon={
                <StarIcon
                  style={{
                    color: '#9CA3AF',
                    width: isMobile ? '16px' : '20px',
                    height: isMobile ? '16px' : '20px',
                  }}
                />
              }
              seeAllLink={AppRoutePath.GAMELIST()}
              games={recommendedGames}
              loading={recommendedGamesLoading}
            />
            {/*          <GameSwiper
            title={t('airbetMenu.liveCasino')}
            icon={<AirbetOriginalMenuIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={liveCasinoGames}
            loading={liveGameLoading}
          />
          <GameSwiper
            title={t('airbetMenu.slots')}
            icon={<AirbetOriginalMenuIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={slotGames}
            loading={slotGameLoading}
          />
          <GameSwiper
            title={t('airbetMenu.tableGames')}
            icon={<AirbetOriginalMenuIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={tableGames}
            loading={tableGameLoading}
          />*/}
            <SportSwiper />
            <ProviderSwiper />
          </div>
        </Container>
      </Box>
      <Box margin={isMobile ? '0px 16px' : '0px 24px'}>
        <Container>
          <Box marginTop={'48px'}>
            <BetsTableContainer />
          </Box>
          <Box marginTop={'32px'}>
            <LobbyAirbetInfo />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Lobby;
