import * as types from '../constants/userConstants';
import {
  IAccessToken,
  IRankInfo,
  IUserInfo,
  IWheelSpinsInfo,
} from '../../types/common.type';
import { IUserProfileInfo } from '../../util/UserProfileHelper';
import { IBonusClaimStatus } from '../../types/Bonus.type';

export const changeUserAuthorization = (isAuthorized: boolean) => ({
  type: types.CHANGE_USER_AUTHORIZATION,
  payload: isAuthorized,
});
export const changeUserInfo = (data: IUserInfo) => ({
  type: types.CHANGE_USER_INFO,
  payload: data,
});
export const changeUserVerificationStatus = (key: string, status: string) => ({
  type: types.CHANGE_USER_INFOVERIFICATION_STATUS,
  payload: { key, status },
});

export const changeAccessToken = (data: IAccessToken) => ({
  type: types.CHANGE_ACCESS_TOKEN,
  payload: data,
});

export const changeLanguage = (language: string) => ({
  type: types.CHANGE_LANGUAGE,
  payload: language,
});

export const changeCountryRestricted = (isRestricted: boolean) => ({
  type: types.CHANGE_IS_CURRENT_COUNTRY_RESTRICTED,
  payload: isRestricted,
});

export const clearUserInfo = () => ({
  type: types.CLEAR_USER_INFO,
});

export const changeRanksInfo = (data: IRankInfo[]) => ({
  type: types.CHANGE_RANKS_INFO,
  payload: data,
});

export const changeUserProfileInfo = (data: IUserProfileInfo) => ({
  type: types.CHANGE_USER_PROFILE_INFORMATION,
  payload: data,
});

export const changeEmail = (data: string) => ({
  type: types.CHANGE_EMAIL,
  payload: data,
});

export const changePhone = (data: string) => ({
  type: types.CHANGE_PHONE,
  payload: data,
});

export const changeGlobalUserLoginModalOpen = (open: boolean) => ({
  type: types.GLOBAL_USER_LOGIN_MODAL_OPEN,
  payload: open,
});

export const changeGlobalUserRegistrationModalOpen = (open: boolean) => ({
  type: types.GLOBAL_USER_REGISTRATION_MODAL_OPEN,
  payload: open,
});

export const changeGlobalUserRegistrationAndLoginModalClose = (
  open: boolean
) => ({
  type: types.GLOBAL_USER_REGISTRATION_AND_LOGIN_MODAL_CLOSE,
  payload: open,
});

export const changeBonusClaimModalStatusInfo = (info: IBonusClaimStatus) => ({
  type: types.BONUS_CLAIM_MODAL_STATUS_INFO,
  payload: info,
});

export const changeBalance = (balance: number) => ({
  type: types.UPDATE_BALANCE,
  payload: balance,
});

export const changeMainHeaderVisibility = (visible: boolean) => ({
  type: types.CHANGE_MAIN_HEADER_VISIBILITY,
  payload: visible,
});

export const globalBonusWheelModalClose = (open: boolean) => ({
  type: types.GLOBAL_BONUS_WHEEL_MODAL_CLOSE,
  payload: open,
});

export const globalBonusWheelModalOpen = (open: boolean) => ({
  type: types.GLOBAL_BONUS_WHEEL_MODAL_OPEN,
  payload: open,
});

export const changeWheelSpinsInfo = (data: IWheelSpinsInfo) => ({
  type: types.CHANGE_WHEEL_SPINS_INFO,
  payload: data,
});

export const changeGlobalDepositModal = (isOpen: boolean) => ({
  type: types.CHANGE_GLOBAL_DEPOSIT_MODAL,
  payload: isOpen,
});

export const changeSideBarOpen = (isOpen: boolean) => ({
  type: types.CHANGE_SIDEBAR_OPEN,
  payload: isOpen,
});
