import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Link } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { isMobile } from 'react-device-detect';
import { Autoplay, Pagination } from 'swiper/modules';
import { loadBanners } from '../../api/services/Banners.api';
import { getUserLanguage } from '../../util/appUtil';
import { IBannerBlog } from '../../types/common.type';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import BannerSkeleton from './BannerSkeleton';

SwiperCore.use([Pagination]);

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    paddingTop: !isMobile ? '24px' : '8px',
    paddingBottom: !isMobile ? '24px' : '8px',
    paddingLeft: !isMobile ? '16px' : '',
    paddingRight: !isMobile ? '16px' : '',
    backgroundColor: '#030712',
    boxSizing: 'border-box',
  },
  navButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: !isMobile ? '226px' : '186px',
    height: '100%',
  },
  swiperContainer: {
    maxWidth: '1308px',
    marginRight: '20px',
    marginLeft: '20px',
    width: '100%',
  },
  skeleton: {
    width: '100%',
    height: '220px',
    minHeight: '220px',
    maxHeight: '220px',
    borderRadius: '8px',
    background: 'rgb(17 24 39)',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: !isMobile ? '24px' : '8px',
    width: '100%',
    height: '30px',
    '& .swiper-pagination-bullet': {
      display: 'inline-block',
      width: '8px',
      height: '8px',
      borderRadius: '100%',
      cursor: 'pointer',
      background: 'rgb(255 255 255 / 80%)',
      marginRight: '5px',
    },
    '& .swiper-pagination-bullet-active': {
      background: '#FFF',
    },
  },
}));

interface IBanner {
  className?: string;
  isChatAndSideOpen: boolean;
}

const Banner: React.FC<IBanner> = ({ isChatAndSideOpen }) => {
  const swiperRef = useRef<any>(null);
  const [slidePerView, setSlidePerView] = useState<number>(3);
  const [bannersLoading, setBannersLoading] = useState<boolean>(false);
  const [banners, setBanners] = useState<
    | {
        data: IBannerBlog;
        success: boolean;
      }
    | undefined
  >();
  const [isInitialSkeleton, setIsInitialSkeleton] = useState<boolean>(true);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const classes = useStyles();
  const pagination = {
    clickable: true,
    el: '.custom-pagination',
  };

  useEffect(() => {
    getBanners();
    setIsFirstRender(true);
  }, []);

  const getBanners = () => {
    setBannersLoading(true);
    loadBanners(getUserLanguage())
      .then((resp) => {
        setBanners(resp.data);
      })
      .catch(() => {})
      .finally(() => {
        setBannersLoading(false);
      });
  };

  const handleLeftButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleRightButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    const slidePerView =
      banners &&
      banners.data &&
      Array.isArray(banners.data) &&
      banners.data.length < 3
        ? 2
        : window.innerWidth > 1200
          ? !isChatAndSideOpen
            ? 3
            : 2
          : window.innerWidth < 900
            ? isChatAndSideOpen
              ? 2
              : 1
            : 2;

    setSlidePerView(slidePerView);
  }, [banners, isChatAndSideOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setSlidePerView(1);
      } else if (window.innerWidth < 1100) {
        setSlidePerView(2);
      } else {
        setSlidePerView(3);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sortedData: IBannerBlog[] | undefined =
    banners && banners.data && Array.isArray(banners.data)
      ? [...banners.data].sort((a: IBannerBlog, b: IBannerBlog) => b.id - a.id)
      : undefined;

  return (
    <Box className={classes.container}>
      <Box className={`${classes.navButtonContainer}`}>
        {!isMobile && !bannersLoading && sortedData?.length !== 0 && (
          <CustomMinimalButton
            style={{
              padding: isMobile ? '6px' : '10px',
              height: isMobile ? '32px' : '40px',
              minWidth: '0px',
              width: isMobile ? '32px' : '40px',
            }}
            onClick={() => handleLeftButtonClick()}
          >
            <span
              style={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowLeftIcon />
            </span>
          </CustomMinimalButton>
        )}
        {bannersLoading && <BannerSkeleton slidePerView={slidePerView} />}
        {!bannersLoading && sortedData && (
          <Swiper
            slidesPerView={isMobile ? 1.15 : slidePerView}
            spaceBetween={24}
            ref={swiperRef}
            autoplay
            pagination={pagination}
            modules={[Pagination, Autoplay]}
            className={classes.swiperContainer}
          >
            {isInitialSkeleton &&
              Array.from({
                length: 3,
              }).map((_, index) => (
                <SwiperSlide key={index}>
                  <Skeleton
                    classes={{ root: classes.skeleton }}
                    key={index}
                    variant="rectangular"
                    className={classes.skeleton}
                  />
                </SwiperSlide>
              ))}
            {sortedData.map((item: IBannerBlog, index: number) => {
              if (isFirstRender && index + 1 === sortedData.length) {
                setIsInitialSkeleton(false);
                setIsFirstRender(false);
              }
              return (
                <SwiperSlide key={item.id}>
                  <Link to={item.button_one_url}>
                    <img
                      src={getBaseUrlStatic() + item.image}
                      alt=""
                      style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '220px',
                        borderRadius: '8px',
                      }}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        {!isMobile && !bannersLoading && sortedData?.length !== 0 && (
          <CustomMinimalButton
            style={{
              padding: isMobile ? '6px' : '10px',
              height: isMobile ? '32px' : '40px',
              minWidth: '0px',
              width: isMobile ? '32px' : '40px',
            }}
            onClick={() => handleRightButtonClick()}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowRightIcon />
            </span>
          </CustomMinimalButton>
        )}
      </Box>
      {!bannersLoading && (
        <Box className={classes.paginationContainer}>
          <Box
            className="custom-pagination"
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="flex-end"
            width={'auto !important'}
          ></Box>
        </Box>
      )}
      {isInitialSkeleton && (
        <Box style={{ width: '100%', height: isMobile ? '38px' : '54px' }} />
      )}
    </Box>
  );
};

export default Banner;
